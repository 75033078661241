import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, Button, ButtonGroup, Card, Chip, CircularProgress, Container, Fab, MenuItem, Paper, Select, Slide, Snackbar, Typography, Zoom } from '@mui/material'
import React, { useState, useContext, useRef, useEffect } from 'react'
import Main from '../components/Main';
import MainHeader from '../components/MainHeader'
import { AuthContext } from '../utils/AuthContext';
import InputTable from '../components/InputTable';
import { ConfigContext } from '../utils/ConfigContext';
import SelectInsurers from '../components/SelectInsurers';
import { downloadTOB, fetchTOB_Data, getRates } from '../services/QuotationService';
import Lifeline_Rates from '../components/Lifeline_Rates';
import BWHO_Rates from '../components/BWHO_Rates';
import Company_Rates from '../components/Company_Rates';
import OilGas_Rates from '../components/OilGas_Rates';
import { useDebounce } from 'use-debounce';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadIcon from '@mui/icons-material/Download';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import AlertDialog from '../components/AlertDialog';
import { toast } from 'react-toastify';
import { fetchAppConfig } from '../services/AppConfigService';
import { addQuotationData, fetchQuotationData, updateQuotationData } from '../services/QuotationDataService';
import { useSearchParams } from 'react-router-dom';
import Download_TOB_FormDialog from '../components/Download_TOB_Form';
import PremiumsSummaryDialog from '../components/PremiumsSummaryDialog';
import FirstChoice_Rates from '../components/FirstChoice_Rates';
import { v4 as uuidv4 } from 'uuid';
import IHHP_Rates from '../components/IHHP_Rates';
import Vumi_Rates from '../components/Vumi_Rates';
import getSafe from '../utils/getSafe';
import MyHealthIndonesia_Rates from '../components/MyHealthIndonesia_Rates';
import MyHealthSingapore_Rates from '../components/MyHealthSingapore_Rates';
import MyHealthVietnam_Rates from '../components/MyHealthVietnam_Rates';
import OptimumGlobal_Rates from '../components/OptimumGlobal_Rates';
import MorganPrice_Rates from '../components/MorganPrice_Rates';
import RatesSettings from '../components/RatesSettings';
import { fetchInsurerRates } from '../services/InsurerRateService';
import QuotationDataFormDialog from '../components/QuotationDataFormDialog';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';



function Quotation1() {

  const { auth, setAuth } = useContext(AuthContext);

  const [open, setOpen] = useState(localStorage.getItem('quoting-drawer-open') == 'true');

  const { appConfig, setAppConfig } = useContext(ConfigContext);

  const currencies = appConfig.content.currencies || [];
  const area_of_coverages = appConfig.content.area_of_coverages || [];
  const relationships = appConfig.content.relationships || [];

  const [showImageCopy, setShowImageCopy] = useState(false);

  function handleCloseSnackbarImageCopy() {
    setShowImageCopy(false);
  }



  const refInputTable = useRef();

  const defaultInsurers = {
    lifeline: false,
    bwho: false,
    bupa: false,
    company: false,
    oil_gas: false,
    first_choice: false,
    ihhp: false,
    vumi: false,
    my_health_indonesia: false,
    my_health_indonesia: false,
    my_health_vietnam: false,
    optimum_global: false,
    myHealthSg: false,
    morgan_price: false
  };

  const insurers_plans_map = {
    'company_essential': { insurer_label: 'BUPA Company', insurer: 'company', plan_label: 'Essential', plan: 'essential' },
    'company_classic': { insurer_label: 'BUPA Company', insurer: 'company', plan_label: 'Classic', plan: 'classic' },
    'company_classic_with_dental': { insurer_label: 'BUPA Company', insurer: 'company', plan_label: 'Classic with Dental', plan: 'classic_with_dental' },
    'company_gold': { insurer_label: 'BUPA Company', insurer: 'company', plan_label: 'Gold', plan: 'gold' },
    'company_gold_with_dental': { insurer_label: 'BUPA Company', insurer: 'company', plan_label: 'Gold with Dental', plan: 'gold_with_dental' },
    'company_gold_superior': { insurer_label: 'BUPA Company', insurer: 'company', plan_label: 'Gold Superior', plan: 'gold_superior' },
    'company_gold_superior_with_dental': { insurer_label: 'BUPA Company', insurer: 'company', plan_label: 'Gold Superior with Dental', plan: 'gold_superior_with_dental' },
    'lifeline_gold': { insurer_label: 'BUPA Lifeline', insurer: 'lifeline', plan_label: 'Gold', plan: 'gold' },
    'lifeline_classic': { insurer_label: 'BUPA Lifeline', insurer: 'lifeline', plan_label: 'Classic', plan: 'classic' },
    'lifeline_essential': { insurer_label: 'BUPA Lifeline', insurer: 'lifeline', plan_label: 'Essential', plan: 'essential' },
    'oil_gas_gold': { insurer_label: 'BUPA Oil & Gas', insurer: 'oil_gas', plan_label: 'Gold', plan: 'gold' },
    'oil_gas_classic': { insurer_label: 'BUPA Oil & Gas', insurer: 'oil_gas', plan_label: 'Classic', plan: 'classic' },
    'oil_gas_essential': { insurer_label: 'BUPA Oil & Gas', insurer: 'oil_gas', plan_label: 'Essential', plan: 'essential' },
    'bwho_wmi': { insurer_label: 'BUPA WHO', insurer: 'bwho', plan_label: 'WMI', plan: 'wmi' },
    'bwho_wmp': { insurer_label: 'BUPA WHO', insurer: 'bwho', plan_label: 'WMI + WMP', plan: 'wmp' },
    'bwho_wme': { insurer_label: 'BUPA WHO', insurer: 'bwho', plan_label: 'WMI + WMP + WME', plan: 'wme' },
    'bwho_ww': { insurer_label: 'BUPA WHO', insurer: 'bwho', plan_label: 'WMI + WMP + WME + WW', plan: 'ww' },
    'first_choice_module_1': { insurer_label: 'BUPA First Choice', insurer: 'first_choice', plan_label: 'Module 1', plan: 'module_1' },
    'first_choice_module_1_2': { insurer_label: 'BUPA First Choice', insurer: 'first_choice', plan_label: 'Module 1 + Module 2', plan: 'module_1_2' },
    'first_choice_module_1_2_3': { insurer_label: 'BUPA First Choice', insurer: 'first_choice', plan_label: 'Module 1 + Module 2 + Module 3', plan: 'module_1_2_3' },
    'first_choice_module_1_2_3_5': { insurer_label: 'BUPA First Choice', insurer: 'first_choice', plan_label: 'Module 1 + Module 2 + Module 3 + Module 5', plan: 'module_1_2_3_5' },
    'first_choice_module_1_2_3_5_6': { insurer_label: 'BUPA First Choice', insurer: 'first_choice', plan_label: 'Module 1 + Module 2 + Module 3 + Module 5 + Module 6', plan: 'module_1_2_3_5_6' },
    'ihhp_hospital_plan': { insurer_label: 'IHHP', insurer: 'ihhp', plan_label: 'Hospital Plan', plan: 'hospital_plan' },
    'ihhp_non_hospitalisation_benefits': { insurer_label: 'IHHP', insurer: 'ihhp', plan_label: 'HP + OP', plan: 'non_hospitalisation_benefits' },
    'ihhp_medicine_appliances': { insurer_label: 'IHHP', insurer: 'ihhp', plan_label: 'HP + OP +  Med', plan: 'medicine_appliances' },
    'ihhp_dental_optical_a': { insurer_label: 'IHHP', insurer: 'ihhp', plan_label: 'HP + OP +  Med + Optical + Dental', plan: 'dental_optical_a' },
    'ihhp_dental_optical_b': { insurer_label: 'IHHP', insurer: 'ihhp', plan_label: 'HP + OP +  Med + Optical + Dental', plan: 'dental_optical_b' },
    'vumi_basic': { insurer_label: 'VUMI', insurer: 'vumi', plan_label: 'Basic', plan: 'basic' },
    'vumi_standard': { insurer_label: 'VUMI', insurer: 'vumi', plan_label: 'Standard', plan: 'standard' },
    'vumi_superior': { insurer_label: 'VUMI', insurer: 'vumi', plan_label: 'Superior', plan: 'superior' },
    'vumi_ultra': { insurer_label: 'VUMI', insurer: 'vumi', plan_label: 'Ultra', plan: 'ultra' },
    'vumi_total': { insurer_label: 'VUMI', insurer: 'vumi', plan_label: 'Total', plan: 'total' },
    'my_health_indonesia': { insurer_label: 'MyHEALTH Indonesia', insurer: 'my_health_indonesia', plan_label: 'MyHEALTH Indonesia', plan: 'my_health_indonesia' },
    'my_health_indonesia_core': { insurer_label: 'MyHEALTH Indonesia', insurer: 'my_health_indonesia', plan_label: 'Core', plan: 'core' },
    'my_health_indonesia_essential': { insurer_label: 'MyHEALTH Indonesia', insurer: 'my_health_indonesia', plan_label: 'Essential', plan: 'essential' },
    'my_health_indonesia_extensive': { insurer_label: 'MyHEALTH Indonesia', insurer: 'my_health_indonesia', plan_label: 'Extensive', plan: 'extensive' },
    'my_health_indonesia_elite': { insurer_label: 'MyHEALTH Indonesia', insurer: 'my_health_indonesia', plan_label: 'Elite', plan: 'elite' },
    'my_health_singapore': { insurer_label: 'MyHEALTH Singapore', insurer: 'my_health_singapore', plan_label: 'MyHEALTH Singapore', plan: 'my_health_singapore' },
    'my_health_singapore_essential': { insurer_label: 'MyHEALTH Singapore', insurer: 'my_health_singapore', plan_label: 'Essential', plan: 'essential' },
    'my_health_singapore_extensive': { insurer_label: 'MyHEALTH Singapore', insurer: 'my_health_singapore', plan_label: 'Extensive', plan: 'extensive' },
    'my_health_singapore_elite': { insurer_label: 'MyHEALTH Singapore', insurer: 'my_health_singapore', plan_label: 'Elite', plan: 'elite' },
    'my_health_vietnam': { insurer_label: 'MyHEALTH Vietnam', insurer: 'my_health_vietnam', plan_label: 'MyHEALTH Vietnam', plan: 'my_health_vietnam' },
    'my_health_vietnam_essential': { insurer_label: 'MyHEALTH Vietnam', insurer: 'my_health_vietnam', plan_label: 'Essential', plan: 'essential' },
    'my_health_vietnam_extensive': { insurer_label: 'MyHEALTH Vietnam', insurer: 'my_health_vietnam', plan_label: 'Extensive', plan: 'extensive' },
    'my_health_vietnam_elite': { insurer_label: 'MyHEALTH Vietnam', insurer: 'my_health_vietnam', plan_label: 'Elite', plan: 'elite' },
    'optimum_global_emerald': { insurer_label: 'Optimum Global', insurer: 'optimum_global', plan_label: 'Emerald', plan: 'emerald' },
    'optimum_global_sapphire': { insurer_label: 'Optimum Global', insurer: 'optimum_global', plan_label: 'Sapphire', plan: 'sapphire' },
    'optimum_global_ruby': { insurer_label: 'Optimum Global', insurer: 'optimum_global', plan_label: 'Ruby', plan: 'ruby' },
    'optimum_global_jade': { insurer_label: 'Optimum Global', insurer: 'optimum_global', plan_label: 'Jade', plan: 'jade' },
    'optimum_global_diamond': { insurer_label: 'Optimum Global', insurer: 'optimum_global', plan_label: 'Diamond', plan: 'diamond' },
    'morgan_price_core_ip': { insurer_label: 'Morgan Price', insurer: 'morgan_price', plan_label: 'Core IP', plan: 'core_ip' },
  };

  const [insurers, setInsurers] = useState(defaultInsurers);

  const { lifeline, bwho, company, oil_gas, first_choice, ihhp, vumi, my_health_indonesia, my_health_singapore, my_health_vietnam, optimum_global, myHealthSg, morgan_price } = insurers;


  const [response, setResponse] = useState(null);
  const [computedRates, setComputedRates] = useState({
    lifeline: null,
    bwho: null,
    company: null,
    oil_gas: null,
    first_choice: null,
    ihhp: null,
    vumi: null,
    my_health_indonesia: null,
    my_health_singapore: null,
    my_health_vietnam: null,
    optimum_global: null,
    morgan_price: null,
  });

  const [isFetchingRates, setFetchingRates] = useState(false);
  const [showFetchingRatesSnackbar, setShowFetchingRatesSnackbar] = useState(false);

  const defaultPlans = appConfig.content.default_plans || [];
  const [selectedPlans, setSelectedPlans] = useState(defaultPlans);

  const [currency, setCurrency] = useState(currencies[0]);
  const [_currency, _setCurrency] = useState(currencies[0]);

  const [paymentFrequency, setPaymentFrequency] = useState('Annually');
  const [areaOfCoverage, setAreaOfCoverage] = useState('');
  const [countryOfResidence, setCountryOfResidence] = useState('');
  const [nationality, setNationality] = useState('');

  const [clients, setClients] = useState([createClient()]);

  const [quotationData, setQuotationData] = useState();
  const [quotationName, setQuotationName] = useState('');
  const [quotationDataFormDialogMode, setQuotationDataFormDialogMode] = useState('');

  const [quotationSaving, setQuotationSaving] = useState(false);
  const [showQuotationDataFormDialog, setShowQuotationDataFormDialog] = useState(false);
  const [clearQuotationConfirmation, setClearQuotationConfirmation] = useState(false);
  const [showDownload_TOB_FormDialog, setShowDownload_TOB_FormDialog] = useState(false);
  const [providedFor, setProvidedFor] = useState('');
  const [tobOutputType, setTobOutputType] = useState('PDF');

  const [fetchingDataQuotation, setFetchingDataQuotation] = useState(false);
  const [quotationDownloading, setQuotationDownloading] = useState(false);
  const defaultDeductibles = {
    lifeline: getSafe(() => appConfig.content.deductibles.lifeline[currencies[0]][0]),
    bwho: {
      wmi_deductible: getSafe(() => appConfig.content.deductibles.bwho.wmi[currencies[0]][0]),
      wme_deductible: getSafe(() => appConfig.content.deductibles.bwho.wme[currencies[0]][0]),
      wmp_deductible: getSafe(() => appConfig.content.deductibles.bwho.wmp[currencies[0]][0]),
      ww_deductible: getSafe(() => appConfig.content.deductibles.bwho.ww[currencies[0]][0])
    },
    company: {
      label: getSafe(() => appConfig.content.deductibles.company[currencies[0]][0]),
      deductible_index: getSafe(() => getCompanyDeductibleIndex(appConfig.content.deductibles.company[currencies[0]][0]))
    },
    first_choice: getSafe(() => appConfig.content.deductibles.first_choice[currencies[0]][0]),
    ihhp: getSafe(() => appConfig.content.deductibles.ihhp[currencies[0]][0]),
    vumi: getSafe(() => appConfig.content.deductibles.vumi['USD'][0]),
    my_health_indonesia: 'Nil Deductible',
    my_health_singapore: 'Nil Deductible',
    my_health_vietnam: 'Nil Deductible',
    optimum_global: 'Nil Deductible',
  }

  const [deductibles, setDeductibles] = useState(defaultDeductibles);



  const [isShowingResult, setShowingResult] = useState(false);
  const [pendingTasks, setPendingTasks] = useState([]);

  const [debouncedPendingTasks] = useDebounce(pendingTasks, 7000);

  const [hasTOBDataChanged, setHasTOBDataChanged] = useState(true);

  // useEffect(() => {
  //   if (pendingTasks.length > 0)
  //   handleExecuteImmediately();
  // }, [debouncedPendingTasks]);

  useEffect(() => {
    setHasTOBDataChanged(true);
  }, [pendingTasks, selectedPlans]);




  const [loadingStatus, setLoadingStatus] = useState({
    lifeline: false,
    bwho: false,
    company: false,
    oil_gas: false,
    first_choice: false,
    ihhp: false,
    vumi: false,
    my_health_indonesia: false,
    my_health_singapore: false,
    my_health_vietnam: false,
    optimum_global: false,
    morgan_price: false,
  });

  const defaultDisplayStatus = {
    lifeline: false,
    bwho: false,
    company: false,
    oil_gas: false,
    first_choice: false,
    ihhp: false,
    vumi: false,
    my_health_indonesia: false,
    my_health_singapore: false,
    my_health_vietnam: false,
    optimum_global: false,
    morgan_price: false,
  };

  const [displayStatus, setDisplayStatus] = useState(defaultDisplayStatus);

  const [showRefreshNow, setShowRefreshNow] = useState(false);

  const [showPremiumsSummaryDialog, setShowPremiumsSummaryDialog] = useState(false);

  const [showRemoveClient, setShowRemoveClient] = useState(false);
  const [removeClientIndex, setRemoveClientIndex] = useState();

  const [searchParams, setSearchParams] = useSearchParams();

  const [recommendedPlans, setRecommendedPlans] = useState([]);
  const [extraPlansForTOB, setExtraPlansForTOB] = useState([]);
  const [tobNotes, setTobNotes] = useState('');

  const [automaticFetchRatesLoading, setAutomaticFetchRatesLoading] = useState(false);

  const [assistanceCovers, setAssistanceCovers] = useState({
    evacuation: false,
    repatriation: false
  });

  const [discounts, setDiscounts] = useState({
    lifeline: 0,
    bwho: 0,
    company: 0,
    oil_gas: 0,
    first_choice: 0,
    ihhp: 0,
    vumi: 0,
    my_health_indonesia: 0,
    my_health_singapore: 0,
    my_health_vietnam: 0,
    optimum_global: 0,
    morgan_price: 0,
  });

  const defaultSpecificAreaOfCoverage = {
    lifeline: 'Worldwide',
    bwho: 'Worldwide',
    company: 'Worldwide',
    oil_gas: 'Worldwide',
    first_choice: 'Worldwide',
    my_health_indonesia: 'Worldwide',
    my_health_singapore: 'Worldwide',
    my_health_vietnam: 'Worldwide',
    ihhp: 'HONG KONG & U.S',
    vumi: 'Worldwide',
    optimum_global: 'Worldwide including USA',
    morgan_price: 'Worldwide excluding USA',
  };

  const [specificAreaOfCoverage, setSpecificAreaOfCoverage] = useState({
    lifeline: '',
    bwho: '',
    company: '',
    oil_gas: '',
    first_choice: '',
    my_health_indonesia: '',
    my_health_singapore: '',
    my_health_vietnam: '',
    ihhp: '',
    vumi: '',
    optimum_global: '',
    morgan_price: '',
  });

  const [specificCurrency, setSpecificCurrency] = useState({
    lifeline: 'USD',
    bwho: 'USD',
    company: 'USD',
    oil_gas: 'USD',
    first_choice: 'USD',
    my_health_indonesia: 'USD',
    my_health_singapore: 'SGD',
    my_health_vietnam: 'USD',
    ihhp: 'USD',
    vumi: 'USD',
    optimum_global: 'USD',
    morgan_price: 'USD',
  });

  useEffect(() => {
    handleSetSpecificAreaOfCoverage();
  }, [insurers, areaOfCoverage]);


  const [benefits, setBenefits] = useState({
    inpatient: {
      index: 5,
      is_checked: true,
      items: [
        { index: 6, name: 'Accomodation Type', is_checked: true },
        { index: 7, name: 'Organ Transplant', is_checked: true },
        { index: 8, name: 'Cancer Treatment', is_checked: true },
        { index: 9, name: 'Congenital Conditions', is_checked: true },
        { index: 10, name: 'Hospice and Palliative Care', is_checked: true },
        { index: 11, name: 'Home Nursing', is_checked: true },
        { index: 12, name: 'Psychiatric Treatment', is_checked: true },
        { index: 13, name: 'Parent Accomodation', is_checked: true },
        { index: 14, name: 'Child Birth', is_checked: true },
        { index: 15, name: 'Pregnancy Complications', is_checked: true },
        { index: 16, name: 'Newborn Care', is_checked: true },
        { index: 17, name: 'Local Road Ambulance', is_checked: true },
        { index: 18, name: 'Local Air Ambulance', is_checked: true },
      ]
    },
    outpatient: {
      index: 19,
      is_checked: true,
      items: [
        { index: 20, name: 'Health Check', is_checked: true },
        { index: 21, name: 'Pathology, X-ray and Diagnostic Tests', is_checked: true },
        { index: 22, name: 'Specialists', is_checked: true },
        { index: 23, name: 'GP Consultations', is_checked: true },
        { index: 24, name: 'Prescribed Medication (non-chronic illness)', is_checked: true },
        { index: 25, name: 'Prescribed Medication (chronic illness)', is_checked: true },
        { index: 26, name: 'Pre/Post Natal', is_checked: true },
        { index: 27, name: 'Treatment by chiropractor, osteopath, homeopath, acupuncturist', is_checked: true },
        { index: 28, name: 'Traditional Chinese Medicine', is_checked: true },
        { index: 29, name: 'Dental', is_checked: true },
        { index: 30, name: 'Optical', is_checked: true },
      ]
    },
    further_benefits: {
      is_checked: true,
      index: 31,
      items: [
        { index: 32, name: 'Coverage of Pre-Existing Conditions', is_checked: true },
        { index: 33, name: 'Chronic Illness', is_checked: true },
        { index: 34, name: 'Kidney Dialysis', is_checked: true },
      ]
    }
  });

  const [listBenefits, setListBenefits] = useState(
    [
      { index: 5, name: 'Inpatient', is_checked: true, is_parent: true },
      { index: 6, name: 'Accomodation Type', is_checked: true, is_parent: false },
      { index: 7, name: 'Organ Transplant', is_checked: true, is_parent: false },
      { index: 8, name: 'Cancer Treatment', is_checked: true, is_parent: false },
      { index: 9, name: 'Congenital Conditions', is_checked: true, is_parent: false },
      { index: 10, name: 'Hospice and Palliative Care', is_checked: true, is_parent: false },
      { index: 11, name: 'Home Nursing', is_checked: true, is_parent: false },
      { index: 12, name: 'Psychiatric Treatment', is_checked: true, is_parent: false },
      { index: 13, name: 'Parent Accomodation', is_checked: true, is_parent: false },
      { index: 14, name: 'Child Birth', is_checked: true, is_parent: false },
      { index: 15, name: 'Pregnancy Complications', is_checked: true, is_parent: false },
      { index: 16, name: 'Newborn Care', is_checked: true, is_parent: false },
      { index: 17, name: 'Local Road Ambulance', is_checked: true, is_parent: false },
      { index: 18, name: 'Local Air Ambulance', is_checked: true, is_parent: false },
      { index: 19, name: 'Outpatient', is_checked: true, is_parent: true },
      { index: 20, name: 'Health Check', is_checked: true, is_parent: false },
      { index: 21, name: 'Pathology, X-ray and Diagnostic Tests', is_checked: true, is_parent: false },
      { index: 22, name: 'Specialists', is_checked: true, is_parent: false },
      { index: 23, name: 'GP Consultations', is_checked: true, is_parent: false },
      { index: 24, name: 'Prescribed Medication (non-chronic illness)', is_checked: true, is_parent: false },
      { index: 25, name: 'Prescribed Medication (chronic illness)', is_checked: true, is_parent: false },
      { index: 26, name: 'Pre/Post Natal', is_checked: true, is_parent: false },
      { index: 27, name: 'Treatment by chiropractor, osteopath, homeopath, acupuncturist', is_checked: true, is_parent: false },
      { index: 28, name: 'Traditional Chinese Medicine', is_checked: true, is_parent: false },
      { index: 29, name: 'Dental', is_checked: true, is_parent: false },
      { index: 30, name: 'Optical', is_checked: true, is_parent: false },
      { index: 31, name: 'Further Benefits', is_checked: true, is_parent: true },
      { index: 32, name: 'Coverage of Pre-Existing Conditions', is_checked: true, is_parent: false },
      { index: 33, name: 'Chronic Illness', is_checked: true, is_parent: false },
      { index: 34, name: 'Kidney Dialysis', is_checked: true, is_parent: false },
    ]
  );

  const [TOB_Data, setTOB_Data] = useState(null);
  const [tableOfBenefits, setTableOfBenefits] = useState(null);
  const [fetchingTOB_Data, setFetchingTOB_Data] = useState(null);

  // MyHealth Indonesia Specifics
  const myHealthID_co_ins = getSafe(() => appConfig.content.specifics.my_health_indonesia.co_ins);
  const myHealthID_plans = getSafe(() => appConfig.content.specifics.my_health_indonesia.plans);
  // const myHealthID_area_of_cover = getSafe(() => appConfig.content.specifics.my_health_indonesia.area_of_cover);

  const [myHealthID_HS_Plan, setMyHealthID_HS_Plan] = useState([myHealthID_plans[0]]);
  const [myHealthID_HS_Deductible, setMyHealthID_HS_Deductible] = useState([getSafe(() => appConfig.content.deductibles.my_health_indonesia['USD'][0])]);
  const [myHealthID_OP_Plan, setMyHealthID_OP_Plan] = useState([myHealthID_plans[0]]);
  const [myHealthID_OP_CoIns, setMyHealthID_OP_CoIns] = useState([getCoInsLabel_ID()[0]]);
  const [myHealthID_MA_Plan, setMyHealthID_MA_Plan] = useState(['N/A']);
  const [myHealthID_DN_Plan, setMyHealthID_DN_Plan] = useState([myHealthID_plans[0]]);

  // MyHealth Singapore Specifics
  const myHealthSG_co_ins = getSafe(() => appConfig.content.specifics.my_health_singapore.co_ins);
  const myHealthSG_plans = getSafe(() => appConfig.content.specifics.my_health_singapore.plans);
  // const myHealthSG_area_of_cover = getSafe(() => appConfig.content.specifics.my_health_singapore.area_of_cover);
  const myHealthSG_ip_network = getSafe(() => appConfig.content.specifics.my_health_singapore.ip_network);

  const [myHealthSG_HS_Plan, setMyHealthSG_HS_Plan] = useState([myHealthSG_plans[0]]);
  const [myHealthSG_HS_IP_Network, setMyHealthSG_HS_IP_Network] = useState([myHealthSG_ip_network[0]]);
  const [myHealthSG_HS_Deductible, setMyHealthSG_HS_Deductible] = useState([getSafe(() => appConfig.content.deductibles.my_health_singapore['SGD'][0])]);
  const [myHealthSG_OP_Plan, setMyHealthSG_OP_Plan] = useState([myHealthSG_plans[0]]);
  const [myHealthSG_OP_CoIns, setMyHealthSG_OP_CoIns] = useState([getCoInsLabel_SG()[0]]);
  const [myHealthSG_MA_Plan, setMyHealthSG_MA_Plan] = useState(['N/A']);
  const [myHealthSG_DN_Plan, setMyHealthSG_DN_Plan] = useState([myHealthSG_plans[0]]);


  // MyHealth Vietnam Specifics
  const myHealthVN_co_ins = getSafe(() => appConfig.content.specifics.my_health_vietnam.co_ins);
  const myHealthVN_plans = getSafe(() => appConfig.content.specifics.my_health_vietnam.plans);
  const myHealthVN_hs_plans = getSafe(() => appConfig.content.specifics.my_health_vietnam.hs_plans);

  const [myHealthVN_HS_Plan, setMyHealthVN_HS_Plan] = useState([myHealthVN_hs_plans[0]]);
  const [myHealthVN_HS_Deductible, setMyHealthVN_HS_Deductible] = useState([getSafe(() => appConfig.content.deductibles.my_health_vietnam['USD'][0])]);
  const [myHealthVN_OP_Plan, setMyHealthVN_OP_Plan] = useState([myHealthVN_plans[0]]);
  const [myHealthVN_OP_CoIns, setMyHealthVN_OP_CoIns] = useState([getCoInsLabel_VN()[0]]);
  const [myHealthVN_MA_Plan, setMyHealthVN_MA_Plan] = useState(['N/A']);
  const [myHealthVN_DN_Plan, setMyHealthVN_DN_Plan] = useState([myHealthVN_plans[0]]);

  const [insurerRates, setInsurerRates] = useState([]);

  const [fetchingInsurerRates, setFetchingInsurerRates] = useState(false);

  const [selectedRatesVersion, setSelectedRatesVersion] = useState({
    lifeline: '',
    lifeline_assistance_cover: '',
    bwho: '',
    bwho_assistance_cover: '',
    company: '',
    company_assistance_cover: '',
    oil_gas: '',
    first_choice: '',
    ihhp: '',
    vumi: '',
    my_health_indonesia: '',
    my_health_singapore: '',
    my_health_vietnam: '',
    optimum_global: '',
    morgan_price: ''
  });

  const [selectedOptions, setSelectedOptions] = useState([]);


  function getCoInsLabel_ID() {
    const labels = [];

    for (let co_ins_item in myHealthID_co_ins) {
      labels.push(co_ins_item);
    }

    return labels;
  }

  function getCoInsLabel_SG() {
    const labels = [];

    for (let co_ins_item in myHealthSG_co_ins) {
      labels.push(co_ins_item);
    }

    return labels;
  }

  function getCoInsLabel_VN() {
    const labels = [];

    for (let co_ins_item in myHealthVN_co_ins) {
      labels.push(co_ins_item);
    }

    return labels;
  }


  useEffect(() => {
    if (isShowingResult) {
      resetDeductibles();
      // handleGetRates(refInputTable.current.getClientData(), getSelectedInsurers());
      addToPendingTasks(getSelectedInsurers());

      setResponse(null);
    }
  }, [currency]);

  useEffect(() => {
    if (isShowingResult) {
      addToPendingTasks(getSelectedInsurers());
    }
  }, [paymentFrequency, areaOfCoverage, countryOfResidence]);

  // useEffect(() => {
  //   if (isShowingResult) {


  //     addToPendingTasks(getSelectedInsurers());
  //   }
  // }, [paymentFrequency]);

  useEffect(function () {
    var id = searchParams.get('id');

    if (id !== null && id !== '') {
      setAutomaticFetchRatesLoading(true);
      handleFetchQuotationData(id)
        .then(function () {
          return handleFetchInsurerRates()
        })
        .then(function () {
          document.getElementById('view-premiums-button').click();
        });
    }

    handleFetchAppConfig();
    handleFetchInsurerRates();
  }, []);

  // useEffect(() => {
  //   if (pendingTasks.length > 0 || showRefreshNow) {
  //     setCountdown(7);
  //   }

  //   const countdownInterval = setInterval(() => {
  //     setCountdown(prev => (prev > 0 ? prev - 1 : 0));
  //   }, 1000);

  //   return () => clearInterval(countdownInterval);
  // }, [pendingTasks, showRefreshNow]);


  function createClient() {
    const uid = uuidv4();

    return {
      uid,
      full_name: '',
      age: '',
      gender: '',
      area_of_coverage: areaOfCoverage,
      currency: currency,
      payment_frequency: paymentFrequency,
      nationality: nationality,
      country_of_residence: countryOfResidence,
      relationship: '',
    }
  }

  function handleSetSpecificAreaOfCoverage() {
    const lifeline_areas = getSafe(() => appConfig.content.set2_area_of_coverage, []);
    const bwho_areas = getSafe(() => appConfig.content.set2_area_of_coverage, []);
    const company_areas = getSafe(() => appConfig.content.set2_area_of_coverage, []);
    const oil_gas_areas = getSafe(() => appConfig.content.set1_area_of_coverage, []);
    const first_choice_areas = getSafe(() => appConfig.content.set3_area_of_coverage, []);
    const ihhp_areas = getSafe(() => appConfig.content.ihhp_area_of_coverage, []);
    const vumi_areas = getSafe(() => appConfig.content.vumi_area_of_coverage, []);
    const my_health_indonesia_areas = getSafe(() => appConfig.content.set4_area_of_coverage, []);
    const my_health_singapore_areas = getSafe(() => appConfig.content.set2_area_of_coverage, []);
    const my_health_vietnam_areas = getSafe(() => appConfig.content.my_health_vietnam_area_of_coverage);
    const optimum_global_areas = getSafe(() => appConfig.content.optimum_global_area_of_coverage, []);
    const morgan_price_areas = getSafe(() => appConfig.content.morgan_price_area_of_coverage, []);


    let area_of_coverage = areaOfCoverage;


    if (area_of_coverage === '' & clients.length > 0) {
      area_of_coverage = clients[0].area_of_coverage;
    }


    const areas = {
      lifeline: lifeline_areas,
      bwho: bwho_areas,
      company: company_areas,
      oil_gas: oil_gas_areas,
      first_choice: first_choice_areas,
      my_health_indonesia: my_health_indonesia_areas,
      my_health_singapore: my_health_singapore_areas,
      my_health_vietnam: my_health_vietnam_areas,
      ihhp: ihhp_areas,
      vumi: vumi_areas,
      optimum_global: optimum_global_areas,
      morgan_price: morgan_price_areas,
    };


    for (var key in insurers) {
      if (insurers.hasOwnProperty(key)) {
        if (insurers[key]) {
          // loop in areas
          const insurer_areas = areas[key];
          let isFound = false;

          for (let i = 0; i < insurer_areas.length; i++) {
            if (insurer_areas[i] === area_of_coverage) {
              isFound = true;
              break;
            }

            // exception
            if (area_of_coverage === 'Worldwide excl USA' && insurer_areas[i] === 'Worldwide excluding USA') {
              area_of_coverage = insurer_areas[i];
              isFound = true;
              break;
            }
          }

          if (isFound) {
            specificAreaOfCoverage[key] = area_of_coverage;
          } else {
            specificAreaOfCoverage[key] = defaultSpecificAreaOfCoverage[key];
          }
        }

      }
    }


    setSpecificAreaOfCoverage({ ...specificAreaOfCoverage });
  }

  async function handleFetchQuotationData(id) {
    setFetchingDataQuotation(true);

    const response = await fetchQuotationData({ token: auth.token, quotationId: id });

    const clients = response.data.quotation.clients;

    setQuotationData(response.data.quotation);
    setClients(clients);

    const selected_insurers = response.data.quotation.selected_insurers;
    const selected_plans = response.data.quotation.selected_plans;
    const selected_rates_versions = response.data.quotation.selected_rates_versions;
    const selected_options = response.data.quotation.selected_options;

    let insurers = defaultInsurers;

    selected_insurers.forEach(selected_insurer => {
      insurers[selected_insurer['name']] = true;
    });

    let selectedPlans = [];

    selected_plans.forEach(selected_plan => {
      selectedPlans.push(selected_plan.name);
    });

    selected_rates_versions.forEach(selected_rates_version => {
      selectedRatesVersion[selected_rates_version.type] = selected_rates_version.version;
    });



    setInsurers(insurers);
    setSelectedPlans(selectedPlans);
    setSelectedRatesVersion({ ...selectedRatesVersion });
    setSelectedOptions(selected_options);

    setCurrency(clients[0]['currency']);
    setPaymentFrequency(clients[0]['payment_frequency']);
    setAreaOfCoverage(clients[0]['area_of_coverage']);

    setFetchingDataQuotation(false);


  }

  async function handleFetchInsurerRates() {
    setFetchingInsurerRates(true);

    const response = await fetchInsurerRates({ token: auth.token });

    setInsurerRates(response.data.insurer_rates);

    setFetchingInsurerRates(false);
  }

  function updateDisplayStatus() {

    setDisplayStatus({
      lifeline,
      bwho,
      company,
      oil_gas,
      first_choice,
      ihhp,
      vumi,
      my_health_indonesia,
      my_health_singapore,
      my_health_vietnam,
      optimum_global,
      morgan_price
    });
  }

  function handleExecuteImmediately() {
    const isValid = handleValidation();

    if (!isValid) return;

    const insurers = showRefreshNow ? getSelectedInsurers() : pendingTasks;

    handleGetRates(refInputTable.current.getClientData(), insurers);

    setPendingTasks([]);

    setShowRefreshNow(false);
  }

  function addToPendingTasks(tasks) {
    const _pendingTasks = JSON.parse(JSON.stringify(pendingTasks));
    const tasksSet = new Set(_pendingTasks);

    tasks.forEach(task => {
      tasksSet.add(task);
    });


    const _pendingTasksUnique = Array.from(tasksSet);

    setPendingTasks(_pendingTasksUnique);
  }

  function removeFromPendingTasks(tasks) {
    const _pendingTasks = JSON.parse(JSON.stringify(pendingTasks));
    const tasksSet = new Set(_pendingTasks);

    tasks.forEach(task => {
      tasksSet.delete(task);
    });

    const _pendingTasksUnique = Array.from(tasksSet);

    setPendingTasks(_pendingTasksUnique);
  }

  async function handleGetRates(clients, insurers) {
    const _insurers = JSON.parse(JSON.stringify(insurers));
    const specifics = {
      my_health_indonesia: _insurers.includes('my_health_indonesia') ? getMyHealthIndonesiaSpecific() : null,
      my_health_singapore: _insurers.includes('my_health_singapore') ? getMyHealthSingaporeSpecific() : null,
      my_health_vietnam: _insurers.includes('my_health_vietnam') ? getMyHealthVietnamSpecific() : null,
      optimum_global: {
        'business_type': 'Individual'
      },
    };

    updateDisplayStatus();
    handleUpdateLoadingStatus(_insurers, true);
    setFetchingRates(true);

    const response = await getRates({
      token: auth.token, body: {
        clients,
        deductibles,
        insurers: _insurers,
        specifics,
        specific_area_of_coverage: specificAreaOfCoverage,
        rates_version_per_insurer: selectedRatesVersion,
        specific_currency: specificCurrency
      }
    }).catch(err => {
      setFetchingRates(false);
    });

    _setCurrency(currency);

    setResponse((prevResponse) => ({
      ...prevResponse,
      ...response.data,
    }));

    handleUpdateLoadingStatus(_insurers, false);
    setFetchingRates(false);

  }

  function getMyHealthIndonesiaSpecific() {
    let specific = [];

    for (let i = 0; i < clients.length; i++) {
      specific.push({
        hs: {
          plan: myHealthID_HS_Plan[i],
          deductible: myHealthID_HS_Deductible[i]
        },
        op: {
          plan: myHealthID_OP_Plan[i],
          co_ins: myHealthID_OP_CoIns[i]
        },
        ma: {
          plan: myHealthID_MA_Plan[i]
        },
        dn: {
          plan: myHealthID_DN_Plan[i]
        }
      });
    }

    return specific;
  }

  function getMyHealthSingaporeSpecific() {
    let specific = [];

    for (let i = 0; i < clients.length; i++) {
      specific.push({
        hs: {
          plan: myHealthSG_HS_Plan[i],
          ip_network: myHealthSG_HS_IP_Network[i],
          deductible: myHealthSG_HS_Deductible[i]
        },
        op: {
          plan: myHealthSG_OP_Plan[i],
          co_ins: myHealthSG_OP_CoIns[i]
        },
        ma: {
          plan: myHealthSG_MA_Plan[i]
        },
        dn: {
          plan: myHealthSG_DN_Plan[i]
        }
      });
    }

    return specific;
  }

  function getMyHealthVietnamSpecific() {
    let specific = [];

    for (let i = 0; i < clients.length; i++) {
      specific.push({
        hs: {
          plan: myHealthVN_HS_Plan[i],
          deductible: myHealthVN_HS_Deductible[i]
        },
        op: {
          plan: myHealthVN_OP_Plan[i],
          co_ins: myHealthVN_OP_CoIns[i]
        },
        ma: {
          plan: myHealthVN_MA_Plan[i]
        },
        dn: {
          plan: myHealthVN_DN_Plan[i]
        }
      });
    }

    return specific;
  }


  function onFetchRatesButtonClicked() {
    const isValid = handleValidation();
    if (!isValid) {
      return;
    }

    const selectedInsurers = getSelectedInsurers();

    const clientData = refInputTable.current.getClientData();
    handleGetRates(clientData, selectedInsurers);

    setPendingTasks([]);
    setShowRefreshNow(false);
    setShowingResult(true);
    setAutomaticFetchRatesLoading(false);

  }

  function handleValidation() {
    if (isFetchingRates) {
      setShowFetchingRatesSnackbar(true);
      return;
    }

    if (clients.length === 0) {
      toast.error('No Client', {
        autoClose: 1000,
        hideProgressBar: true,
      });
      return false;
    }

    const isFormValid = refInputTable.current.validateForm();

    if (!isFormValid) {
      toast.error('Invalid Form', {
        autoClose: 1000,
        hideProgressBar: true,
      });
      return false;
    }

    const selectedInsurers = getSelectedInsurers();

    if (selectedInsurers.length === 0) {
      toast.error('Select Insurer', {
        autoClose: 1000,
        hideProgressBar: true,
      });

      return false;
    }

    return true;
  }

  function getSelectedInsurers() {
    const selectedInsurers = [];

    for (const key in insurers) {
      if (insurers[key]) {
        selectedInsurers.push(key);
      }
    }

    return selectedInsurers;
  }

  function handleSetAllLoading() {
    handleUpdateLoadingStatus(getSelectedInsurers(), true);
  }

  function handleUpdateLoadingStatus(insurers, val) {
    const _loadingStatus = JSON.parse(JSON.stringify(loadingStatus));


    insurers.forEach(insurer => {
      _loadingStatus[insurer] = val;
    })


    setLoadingStatus(_loadingStatus);
  }

  function resetDeductibles() {
    setDeductibles({
      lifeline: getSafe(() => appConfig.content.deductibles.lifeline[currency][0]),
      bwho: {
        wmi_deductible: getSafe(() => appConfig.content.deductibles.bwho.wmi[currency][0]),
        wme_deductible: getSafe(() => appConfig.content.deductibles.bwho.wme[currency][0]),
        wmp_deductible: getSafe(() => appConfig.content.deductibles.bwho.wmp[currency][0]),
        ww_deductible: getSafe(() => appConfig.content.deductibles.bwho.ww[currency][0])
      },
      company: {
        label: getSafe(() => appConfig.content.deductibles.company[currency][0]),
        deductible_index: getSafe(() => getCompanyDeductibleIndex(appConfig.content.deductibles.company[currency][0]))
      },
      first_choice: getSafe(() => appConfig.content.deductibles.first_choice[currency][0]),
      ihhp: getSafe(() => appConfig.content.deductibles.ihhp[currency][0]),
      vumi: getSafe(() => appConfig.content.deductibles.vumi['USD'][0]),
      my_health_indonesia: 'Nil Deductible',
      my_health_singapore: 'Nil Deductible',
      my_health_vietnam: 'Nil Deductible',
      optimum_global: 'Nil Deductible',
    });
  }

  // company insurer specific
  function getCompanyDeductibleIndex(deductible) {
    const deductibles = appConfig.content.deductibles.company[currency];

    for (let i = 0; i < deductibles.length; i++) {
      if (deductible === deductibles[i]) {
        return 'D' + i;
      }
    }

    return 'D0';
  }

  function handleRemoveClient(index) {
    const _clients = JSON.parse(JSON.stringify(clients));

    _clients.splice(index, 1);

    setClients(_clients);

    setShowRefreshNow(true);
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowFetchingRatesSnackbar(false);
  };

  async function handleFetchAppConfig() {
    const res = await fetchAppConfig({ token: auth.token });

    const config = res.data;

    const configCurrentTimestamp = localStorage.getItem('quoting-app-config-timestamp');

    if (configCurrentTimestamp !== config.timestamp) {
      localStorage.setItem('quoting-app-config-timestamp', config.timestamp);
      localStorage.setItem('quoting-app-config', config.content);

      setAppConfig({
        content: JSON.parse(config.content),
        configTimestamp: config.timestamp
      });

      window.location.reload();
    }
  }

  function onSaveQuotationButtonClicked() {
    if (quotationSaving) { return; }

    setShowQuotationDataFormDialog(true);

    if (quotationData == null) {
      setQuotationDataFormDialogMode('add');
    } else {
      setQuotationDataFormDialogMode('update');
    }
  }

  function handleSaveQuotationData(quotationName) {
    const isValid = handleValidation();

    if (!isValid) { return; }

    if (quotationData == null) {
      handleAddQuotationData(quotationName);
    } else {
      handleUpdateQuotationData(quotationName);
    }
  }

  async function handleAddQuotationData(quotationName) {
    if (quotationSaving == true) {
      //prevent clicking while saving...
      return;
    }

    setQuotationSaving(true);

    const id = toast.loading("Saving Data...");

    const response = await addQuotationData({
      token: auth.token, body: {
        clients,
        selected_insurers: getSelectedInsurers(insurers),
        selected_plans: selectedPlans,
        selected_rates_versions: selectedRatesVersion,
        selected_options: getSelectedOptions(),
        name: quotationName
      }
    });

    toast.update(id, {
      type: 'success',
      render: 'Quotation Data Saved',
      autoClose: 1500,
      hideProgressBar: true,
      isLoading: false
    });

    const quotation = response.data.quotation;

    setQuotationData(response.data.quotation);

    searchParams.set('id', quotation.id);
    setSearchParams(searchParams);

    setQuotationSaving(false);
  }

  async function handleUpdateQuotationData(quotationName) {
    setQuotationSaving(true);

    const id = toast.loading("Saving Data...")

    const response = await updateQuotationData({
      quotationId: quotationData.id,
      token: auth.token,
      body: {
        clients,
        selected_insurers: getSelectedInsurers(insurers),
        selected_plans: selectedPlans,
        selected_rates_versions: selectedRatesVersion,
        selected_options: getSelectedOptions(),
        name: quotationName
      }
    });

    toast.update(id, {
      type: 'success',
      render: 'Quotation Data Updated',
      autoClose: 1500,
      hideProgressBar: true,
      isLoading: false
    });

    setQuotationData(response.data.quotation);

    setQuotationSaving(false);
  }

  function getSelectedOptions() {
    const selected_options = [];

    for (let i = 0; i < selectedOptions.length; i++) {
      if (insurers[selectedOptions[i].insurer]) {
        selected_options.push(selectedOptions[i]);
      }
    }

    return selected_options;
  }

  function handleNewQuotation() {
    // empty clients
    setClients([createClient()]);

    // delete query params
    searchParams.delete('id');
    setSearchParams(searchParams);

    setInsurers(defaultInsurers);

    setShowingResult(false);
    setSelectedPlans(defaultPlans);
    setQuotationData(null);

    setDisplayStatus(defaultDisplayStatus);

    setSelectedOptions([]);

    

    toast.success('Quotation Form Cleared', {
      autoClose: 1500,
      hideProgressBar: true,
    });

    
  }

  function filterSelectedInsurerPlan(blackList = []) {
    const filteredSelectedPlans = [];

    const lifelinePlans = ['lifeline_essential', 'lifeline_classic', 'lifeline_gold'];
    const bwhoPlans = ['bwho_wmi', 'bwho_wme', 'bwho_wmp', 'bwho_ww'];
    const companyPlans = ['company_essential', 'company_classic', 'company_classic_with_dental', 'company_gold', 'company_gold_with_dental', 'company_gold_superior', 'company_gold_superior_with_dental'];
    const oilGasPlans = ['oil_gas_essential', 'oil_gas_classic', 'oil_gas_gold'];
    const firstChoicePlans = ['first_choice_module_1', 'first_choice_module_1_2', 'first_choice_module_1_2_3',
      'first_choice_module_1_2_3_5', 'first_choice_module_1_2_3_5_6',];
    const ihhpPlans = ['ihhp_hospital_plan', 'ihhp_non_hospitalisation_benefits', 'ihhp_medicine_appliances', 'ihhp_dental_optical_a', 'ihhp_dental_optical_b'];
    const vumiPlans = ['vumi_basic', 'vumi_standard', 'vumi_superior', 'vumi_ultra', 'vumi_total'];
    const myHealthIndonesiaPlans = ['my_health_indonesia'];
    const myHealthSingaporePlans = ['my_health_singapore'];
    const myHealthVietnamPlans = ['my_health_vietnam'];
    const optimumGlobalPlans = ['optimum_global_emerald', 'optimum_global_sapphire', 'optimum_global_ruby', 'optimum_global_jade', 'optimum_global_diamond'];
    const morganPricePlans = ['morgan_price_core_ip'];

    selectedPlans.forEach(selectedPlan => {
      if (lifeline && lifelinePlans.includes(selectedPlan)) {
        filteredSelectedPlans.push(selectedPlan);
      } else if (bwho && bwhoPlans.includes(selectedPlan)) {
        filteredSelectedPlans.push(selectedPlan);
      } else if (company && companyPlans.includes(selectedPlan)) {
        filteredSelectedPlans.push(selectedPlan);
      } else if (oil_gas && oilGasPlans.includes(selectedPlan)) {
        filteredSelectedPlans.push(selectedPlan);
      } else if (first_choice && firstChoicePlans.includes(selectedPlan)) {
        filteredSelectedPlans.push(selectedPlan);
      } else if (ihhp && ihhpPlans.includes(selectedPlan)) {
        filteredSelectedPlans.push(selectedPlan);
      } else if (vumi && vumiPlans.includes(selectedPlan)) {
        filteredSelectedPlans.push(selectedPlan);
      } else if (my_health_indonesia && myHealthIndonesiaPlans.includes(selectedPlan)) {
        if (!blackList.includes(selectedPlan)) {
          filteredSelectedPlans.push(selectedPlan);
        }

      } else if (my_health_singapore && myHealthSingaporePlans.includes(selectedPlan)) {
        if (!blackList.includes(selectedPlan)) {
          filteredSelectedPlans.push(selectedPlan);
        }

      } else if (my_health_vietnam && myHealthVietnamPlans.includes(selectedPlan)) {
        if (!blackList.includes(selectedPlan)) {
          filteredSelectedPlans.push(selectedPlan);
        }

      } else if (optimum_global && optimumGlobalPlans.includes(selectedPlan)) {
        filteredSelectedPlans.push(selectedPlan);
      } else if (morgan_price && morganPricePlans.includes(selectedPlan)) {
        filteredSelectedPlans.push(selectedPlan);
      }
    });

    return filteredSelectedPlans;
  }

  function getRatesForTOB() {
    const rates = [];
    const selected_plans = filterSelectedInsurerPlan(['my_health_indonesia', 'my_health_singapore', 'my_health_vietnam']);

    clients.forEach((client, index) => {
      let obj = {};

      obj['client'] = client;

      selected_plans.forEach(selectedPlan => {
        const insurer = insurers_plans_map[selectedPlan].insurer;
        const plan = insurers_plans_map[selectedPlan].plan;
        if (computedRates[insurer]) {
          obj[selectedPlan] = computedRates[insurer][index][plan];
        }
      });

      extraPlansForTOB.forEach(selectedPlan => {
        const insurer = insurers_plans_map[selectedPlan].insurer;
        // const plan = insurers_plans_map[selectedPlan].plan;

        if (['my_health_indonesia_core', 'my_health_indonesia_essential', 'my_health_indonesia_extensive', 'my_health_indonesia_elite'].includes(selectedPlan)) {
          obj[selectedPlan] = computedRates[insurer][index][insurer];
        }


        if (['my_health_singapore_essential', 'my_health_singapore_extensive', 'my_health_singapore_elite'].includes(selectedPlan)) {
          obj[selectedPlan] = computedRates[insurer][index][insurer];
        }

        if (['my_health_vietnam_essential', 'my_health_vietnam_extensive', 'my_health_vietnam_elite'].includes(selectedPlan)) {
          obj[selectedPlan] = computedRates[insurer][index][insurer];
        }


      });

      rates.push(obj);
    });



    return rates;
  }

  function handleDownloadTOB(providedFor, tobNotes) {
    if (quotationDownloading) { return; }


    if (selectedPlans.length == 0) {
      toast.error('Please select a plan', {
        autoClose: 1500,
        hideProgressBar: true,
      });

      return;
    }

    const id = toast.loading("Downloading...");

    setQuotationDownloading(true);

    const selected_plans = filterSelectedInsurerPlan(['my_health_indonesia', 'my_health_singapore', 'my_health_vietnam']);

    selected_plans.push(...extraPlansForTOB)

    downloadTOB({
      token: auth.token, body: {
        provided_for: providedFor,
        clients,
        currency: currency,
        payment_frequency: paymentFrequency,
        area_of_coverage: areaOfCoverage,
        selected_plans,
        rates: getRatesForTOB(),
        assistance_covers: assistanceCovers,
        recommended_plans: recommendedPlans,
        tob_notes: tobNotes,
        list_benefits: listBenefits,
        table_of_benefits: tableOfBenefits,
        output_type: tobOutputType,
        specific_area_of_coverage: specificAreaOfCoverage
      }
    }).then((response) => {
      toast.update(id, {
        type: 'success',
        render: 'Quotation Downloaded',
        autoClose: 1500,
        hideProgressBar: true,
        isLoading: false
      });

      setQuotationDownloading(false);
      const str = response.headers['content-disposition'];
      console.log(str);
      const match = str.match(/filename="([^"]+)"/);
      let filename = 'Quotation.pdf'
      if (match) {
        filename = match[1];
      } else {
        console.log('Filename not found.');
      }

      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', filename); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);


    }).catch(err => {
      console.log(err);

      toast.update(id, {
        type: 'error',
        render: 'An Error Has Occurred',
        autoClose: 1500,
        hideProgressBar: true,
        isLoading: false
      });

      setQuotationDownloading(false);
    })
  }

  function handleFetchTOB_Data() {
    setFetchingTOB_Data(true);

    const selected_plans = filterSelectedInsurerPlan(['my_health_indonesia', 'my_health_singapore', 'my_health_vietnam']);

    selected_plans.push(...extraPlansForTOB)

    fetchTOB_Data({
      token: auth.token, body: {
        clients,
        currency: currency,
        payment_frequency: paymentFrequency,
        area_of_coverage: areaOfCoverage,
        selected_plans: selected_plans,
        rates: getRatesForTOB(),
        assistance_covers: assistanceCovers,
        recommended_plans: recommendedPlans,
        // extra_plans: extraPlansForTOB,
        tob_notes: tobNotes,
        benefits,
        return_data_only: true,
        specific_area_of_coverage: specificAreaOfCoverage,
        specific_currency: specificCurrency
      }
    }).then((response) => {
      setTOB_Data(response.data);
      setTableOfBenefits(response.data.table);
      setFetchingTOB_Data(false);
    }).catch(err => {
      console.log(err);
      setFetchingTOB_Data(false);
    })
  }

  function isAtleastOneInsurerShowing() {
    for (let key in displayStatus) {
      if (displayStatus.hasOwnProperty(key)) {  // to filter out properties from the prototype chain
        if (displayStatus[key]) {
          return true;
        }
      }
    }

    return false;
  }

  // function getSafe(fn, defaultVal) {
  //   try {
  //     return fn();
  //   } catch (e) {
  //     return defaultVal;
  //   }
  // }

  function addSelectedOption(insurer, type, option_value) {
    let index = -1;

    for (let i = 0; i < selectedOptions.length; i++) {
      if (insurer === selectedOptions[i].insurer && type === selectedOptions[i].type) {
        index = i;
        break;
      }
    }

    if (index !== -1) {
      selectedOptions[index] = {
        insurer,
        type,
        option_value
      }
    } else {
      selectedOptions.push({
        insurer,
        type,
        option_value
      });
    }
  }

  function setViewSelectedOption(insurer, type, setValue) {
    const selectedOption = findSelectedOption(insurer, type);

    if (selectedOption) {
      setValue(selectedOption.option_value);
    }
  }

  function findSelectedOption(insurer, type) {

    for (let i = 0; i < selectedOptions.length; i++) {
      if (insurer === selectedOptions[i].insurer && type === selectedOptions[i].type) {
        return selectedOptions[i];
      }
    }

    return null;
  }


  return (
    <Box sx={{ display: 'flex' }}>
      <Slide in={pendingTasks.length || showRefreshNow}>
        <Fab
          onClick={handleExecuteImmediately}
          style={{ position: 'fixed', top: 100, left: '50vw', backgroundColor: '#bbdefb' }}
          color='default'
          variant="extended">
          Click to Refresh
          <RefreshIcon sx={{ ml: 1 }} />
        </Fab>
      </Slide>
      <MainHeader open={open} setOpen={setOpen} />
      <Main open={open} sx={{ pt: 7, }} >
        <Container maxWidth="xl">
          <Typography variant='h4' sx={{ fontWeight: 600, mt: 4, mb: 2 }}>Quotation
          </Typography>
          {
            quotationData != null &&
            <Chip
              color='primary'
              // icon={<ArrowRightIcon />}
              label={quotationData.name}
              sx={{ fontSize: 20 }}
              onDelete={() => {
                setClearQuotationConfirmation(true);
              }}></Chip>
          }

          <Box sx={{ display: fetchingDataQuotation === true ? 'block' : 'none' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
              <CircularProgress />

            </Box>

          </Box>

          <Box sx={{ display: fetchingDataQuotation === true ? 'none' : 'flex', justifyContent: 'center' }}>
            <Box sx={{ width: open ? 'calc(95vw - 240px)' : '95vw' }}>
              <Box sx={{ display: 'flex', justifyContent: 'end', mb: 2 }}>
                <ButtonGroup variant='contained'>
                  <Button onClick={() => {
                    setClearQuotationConfirmation(true);
                  }}>New Quotation</Button>
                  <Button onClick={onSaveQuotationButtonClicked}>Save Quotation</Button>
                  <Button
                    onClick={() => {
                      setClients([
                        ...clients,
                        createClient()
                      ]);

                      // MY HEALTH ID
                      setMyHealthID_HS_Plan([...myHealthID_HS_Plan, myHealthID_plans[0]]);
                      setMyHealthID_HS_Deductible([...myHealthID_HS_Deductible, getSafe(() => appConfig.content.deductibles.my_health_indonesia['USD'][0])]);
                      setMyHealthID_OP_Plan([...myHealthID_OP_Plan, myHealthID_plans[0]]);
                      setMyHealthID_OP_CoIns([...myHealthID_OP_CoIns, getCoInsLabel_ID()[0]]);
                      setMyHealthID_MA_Plan([...myHealthID_MA_Plan, 'N/A']);
                      setMyHealthID_DN_Plan([...myHealthID_DN_Plan, myHealthID_plans[0]]);

                      // MY HEALTH SG
                      setMyHealthSG_HS_Plan([...myHealthSG_HS_Plan, myHealthSG_plans[0]]);
                      setMyHealthSG_HS_IP_Network([...myHealthSG_HS_IP_Network, myHealthSG_ip_network[0]]);
                      setMyHealthSG_HS_Deductible([...myHealthSG_HS_Deductible, getSafe(() => appConfig.content.deductibles.my_health_singapore['SGD'][0])]);
                      setMyHealthSG_OP_Plan([...myHealthSG_OP_Plan, myHealthSG_plans[0]]);
                      setMyHealthSG_OP_CoIns([...myHealthSG_OP_CoIns, getCoInsLabel_SG()[0]]);
                      setMyHealthSG_MA_Plan([...myHealthSG_MA_Plan, 'N/A']);
                      setMyHealthSG_DN_Plan([...myHealthSG_DN_Plan, myHealthSG_plans[0]]);

                      // MY HEALTH vn
                      setMyHealthVN_HS_Plan([...myHealthVN_HS_Plan, myHealthVN_hs_plans[0]]);
                      setMyHealthVN_HS_Deductible([...myHealthVN_HS_Deductible, getSafe(() => appConfig.content.deductibles.my_health_vietnam['USD'][0])]);
                      setMyHealthVN_OP_Plan([...myHealthVN_OP_Plan, myHealthVN_plans[0]]);
                      setMyHealthVN_OP_CoIns([...myHealthVN_OP_CoIns, getCoInsLabel_VN()[0]]);
                      setMyHealthVN_MA_Plan([...myHealthVN_MA_Plan, 'N/A']);
                      setMyHealthVN_DN_Plan([...myHealthVN_DN_Plan, myHealthVN_plans[0]]);

                      if (isShowingResult) {
                        setShowRefreshNow(true);
                      }

                      setSelectedOptions([]);
                      setSelectedPlans(defaultPlans);

                    }}
                  >Add Client</Button>
                </ButtonGroup>
              </Box>

              <InputTable
                ref={refInputTable}
                clients={clients}
                setClients={setClients}
                area_of_coverages={area_of_coverages}
                currencies={currencies}
                relationships={relationships}
                setCurrency={setCurrency}
                setPaymentFrequency={setPaymentFrequency}
                setAreaOfCoverage={setAreaOfCoverage}
                setCountryOfResidence={setCountryOfResidence}
                setNationality={setNationality}
                setShowRefreshNow={setShowRefreshNow}
                isShowingResult={isShowingResult}
                setShowRemoveClient={setShowRemoveClient}
                setRemoveClientIndex={setRemoveClientIndex}
              />

              <SelectInsurers
                showResult={isShowingResult}
                fetchQuotationByKey={() => { }}
                insurers={insurers}
                setInsurers={setInsurers}
                sx={{ mt: 3 }}
                pendingTasks={pendingTasks}
                setPendingTasks={setPendingTasks}
                displayStatus={displayStatus}
                setDisplayStatus={setDisplayStatus}
                updateDisplayStatus={updateDisplayStatus}
              />

              <RatesSettings
                sx={{ my: 2 }}
                insurerRates={insurerRates}
                selectedRatesVersion={selectedRatesVersion}
                setSelectedRatesVersion={setSelectedRatesVersion}
              />

              <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2, mb: 2 }}>
                <Button
                  id='view-premiums-button'
                  variant='contained'
                  endIcon={<ManageSearchIcon />}
                  onClick={onFetchRatesButtonClicked}>View Premiums</Button>
              </Box>

              {
                automaticFetchRatesLoading &&
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 5
                }}>
                  <CircularProgress />
                </Box>
              }


              {/* <Box sx={{ display: 'flex', mt: 2, mb: 2 }}>
              <Button variant='contained'>Download TOB</Button>
            </Box> */}

              <Zoom in={isShowingResult && isAtleastOneInsurerShowing()}>
                <ButtonGroup variant='contained' sx={{ mt: 2, mb: 3 }}>
                  <Button onClick={() => {
                    setShowPremiumsSummaryDialog(true);
                  }}>VIEW PLAN COMPARISON</Button>
                  <Badge badgeContent={filterSelectedInsurerPlan([]).length} color='secondary'>
                    <Button onClick={() => {
                      setShowDownload_TOB_FormDialog(true);


                      if (hasTOBDataChanged) {
                        handleFetchTOB_Data();
                        setHasTOBDataChanged(false);
                      }

                    }} endIcon={<DownloadIcon />}>Download TOB</Button>
                  </Badge>
                </ButtonGroup>
              </Zoom>


              <Lifeline_Rates
                sx={{ display: displayStatus['lifeline'] ? 'block' : 'none', mb: 2 }}
                clients={clients}
                currency={currency}
                selectedPlans={selectedPlans}
                setSelectedPlans={setSelectedPlans}
                loadingStatus={loadingStatus}
                setLoadingStatus={setLoadingStatus}
                response={response ? response.lifeline : null}
                setResponse={setResponse}
                deductibles={deductibles}
                setDeductibles={setDeductibles}
                addToPendingTasks={addToPendingTasks}
                discounts={discounts}
                setDiscounts={setDiscounts}
                computedRates={computedRates}
                setComputedRates={setComputedRates}
                specificAreaOfCoverage={specificAreaOfCoverage}
                setSpecificAreaOfCoverage={setSpecificAreaOfCoverage}
                areaOfCoverage={areaOfCoverage}
                setShowImageCopy={setShowImageCopy}
                selectedOptions={selectedOptions}
                addSelectedOption={addSelectedOption}
                setViewSelectedOption={setViewSelectedOption}
                specificCurrency={specificCurrency}
                setSpecificCurrency={setSpecificCurrency}
              />

              <BWHO_Rates
                sx={{ display: displayStatus['bwho'] ? 'block' : 'none', mb: 2 }}
                clients={clients}
                currency={currency}
                selectedPlans={selectedPlans}
                setSelectedPlans={setSelectedPlans}
                loadingStatus={loadingStatus}
                setLoadingStatus={setLoadingStatus}
                response={response ? response.bwho : null}
                deductibles={deductibles}
                setDeductibles={setDeductibles}
                addToPendingTasks={addToPendingTasks}
                discounts={discounts}
                setDiscounts={setDiscounts}
                computedRates={computedRates}
                setComputedRates={setComputedRates}
                specificAreaOfCoverage={specificAreaOfCoverage}
                setSpecificAreaOfCoverage={setSpecificAreaOfCoverage}
                areaOfCoverage={areaOfCoverage}
                setShowImageCopy={setShowImageCopy}
                selectedOptions={selectedOptions}
                addSelectedOption={addSelectedOption}
                setViewSelectedOption={setViewSelectedOption}
                specificCurrency={specificCurrency}
                setSpecificCurrency={setSpecificCurrency}
              />

              <Company_Rates
                sx={{ display: displayStatus['company'] ? 'block' : 'none', mb: 2 }}
                clients={clients}
                currency={currency}
                selectedPlans={selectedPlans}
                setSelectedPlans={setSelectedPlans}
                loadingStatus={loadingStatus}
                setLoadingStatus={setLoadingStatus}
                response={response ? response.company : null}
                deductibles={deductibles}
                setDeductibles={setDeductibles}
                addToPendingTasks={addToPendingTasks}
                discounts={discounts}
                setDiscounts={setDiscounts}
                computedRates={computedRates}
                setComputedRates={setComputedRates}
                specificAreaOfCoverage={specificAreaOfCoverage}
                setSpecificAreaOfCoverage={setSpecificAreaOfCoverage}
                areaOfCoverage={areaOfCoverage}
                setShowImageCopy={setShowImageCopy}
                selectedOptions={selectedOptions}
                addSelectedOption={addSelectedOption}
                setViewSelectedOption={setViewSelectedOption}
                paymentFrequency={paymentFrequency}
                specificCurrency={specificCurrency}
                setSpecificCurrency={setSpecificCurrency}
              />

              <OilGas_Rates
                sx={{ display: displayStatus['oil_gas'] ? 'block' : 'none', mb: 2 }}
                clients={clients}
                currency={currency}
                selectedPlans={selectedPlans}
                setSelectedPlans={setSelectedPlans}
                loadingStatus={loadingStatus}
                setLoadingStatus={setLoadingStatus}
                response={response ? response.oil_gas : null}
                addToPendingTasks={addToPendingTasks}
                discounts={discounts}
                setDiscounts={setDiscounts}
                computedRates={computedRates}
                setComputedRates={setComputedRates}
                specificAreaOfCoverage={specificAreaOfCoverage}
                setSpecificAreaOfCoverage={setSpecificAreaOfCoverage}
                areaOfCoverage={areaOfCoverage}
                setShowImageCopy={setShowImageCopy}
                selectedOptions={selectedOptions}
                addSelectedOption={addSelectedOption}
                setViewSelectedOption={setViewSelectedOption}
                paymentFrequency={paymentFrequency}
                specificCurrency={specificCurrency}
                setSpecificCurrency={setSpecificCurrency}
              />

              <FirstChoice_Rates
                sx={{ display: displayStatus['first_choice'] ? 'block' : 'none', mb: 2 }}
                clients={clients}
                currency={currency}
                selectedPlans={selectedPlans}
                setSelectedPlans={setSelectedPlans}
                loadingStatus={loadingStatus}
                setLoadingStatus={setLoadingStatus}
                response={response ? response.first_choice : null}
                deductibles={deductibles}
                setDeductibles={setDeductibles}
                addToPendingTasks={addToPendingTasks}
                discounts={discounts}
                setDiscounts={setDiscounts}
                computedRates={computedRates}
                setComputedRates={setComputedRates}
                specificAreaOfCoverage={specificAreaOfCoverage}
                setSpecificAreaOfCoverage={setSpecificAreaOfCoverage}
                areaOfCoverage={areaOfCoverage}
                setShowImageCopy={setShowImageCopy}
                selectedOptions={selectedOptions}
                addSelectedOption={addSelectedOption}
                setViewSelectedOption={setViewSelectedOption}
                specificCurrency={specificCurrency}
                setSpecificCurrency={setSpecificCurrency}
              />

              <IHHP_Rates
                sx={{ display: displayStatus['ihhp'] ? 'block' : 'none', mb: 2 }}
                clients={clients}
                currency={currency}
                selectedPlans={selectedPlans}
                setSelectedPlans={setSelectedPlans}
                loadingStatus={loadingStatus}
                setLoadingStatus={setLoadingStatus}
                response={response ? response.ihhp : null}
                deductibles={deductibles}
                setDeductibles={setDeductibles}
                addToPendingTasks={addToPendingTasks}
                discounts={discounts}
                setDiscounts={setDiscounts}
                computedRates={computedRates}
                setComputedRates={setComputedRates}
                areaOfCoverage={areaOfCoverage}
                specificAreaOfCoverage={specificAreaOfCoverage}
                setSpecificAreaOfCoverage={setSpecificAreaOfCoverage}
                setShowImageCopy={setShowImageCopy}
                selectedOptions={selectedOptions}
                addSelectedOption={addSelectedOption}
                setViewSelectedOption={setViewSelectedOption}
                specificCurrency={specificCurrency}
                setSpecificCurrency={setSpecificCurrency}
              />

              <Vumi_Rates
                sx={{ display: displayStatus['vumi'] ? 'block' : 'none', mb: 2 }}
                clients={clients}
                currency={currency}
                selectedPlans={selectedPlans}
                setSelectedPlans={setSelectedPlans}
                loadingStatus={loadingStatus}
                setLoadingStatus={setLoadingStatus}
                response={response ? response.vumi : null}
                deductibles={deductibles}
                setDeductibles={setDeductibles}
                addToPendingTasks={addToPendingTasks}
                discounts={discounts}
                setDiscounts={setDiscounts}
                computedRates={computedRates}
                setComputedRates={setComputedRates}
                specificAreaOfCoverage={specificAreaOfCoverage}
                setSpecificAreaOfCoverage={setSpecificAreaOfCoverage}
                areaOfCoverage={areaOfCoverage}
                setShowImageCopy={setShowImageCopy}
                selectedOptions={selectedOptions}
                addSelectedOption={addSelectedOption}
                setViewSelectedOption={setViewSelectedOption}
                specificCurrency={specificCurrency}
                setSpecificCurrency={setSpecificCurrency}
              />

              <MyHealthIndonesia_Rates
                sx={{ display: displayStatus['my_health_indonesia'] ? 'block' : 'none', mb: 2 }}
                my_health_indonesia={my_health_indonesia}
                clients={clients}
                currency={currency}
                selectedPlans={selectedPlans}
                setSelectedPlans={setSelectedPlans}
                loadingStatus={loadingStatus}
                setLoadingStatus={setLoadingStatus}
                response={response ? response.my_health_indonesia : null}
                deductibles={deductibles}
                setDeductibles={setDeductibles}
                addToPendingTasks={addToPendingTasks}
                removeFromPendingTasks={removeFromPendingTasks}
                discounts={discounts}
                setDiscounts={setDiscounts}
                computedRates={computedRates}
                setComputedRates={setComputedRates}
                myHealthID_HS_Plan={myHealthID_HS_Plan}
                setMyHealthID_HS_Plan={setMyHealthID_HS_Plan}
                myHealthID_HS_Deductible={myHealthID_HS_Deductible}
                setMyHealthID_HS_Deductible={setMyHealthID_HS_Deductible}
                myHealthID_OP_Plan={myHealthID_OP_Plan}
                setMyHealthID_OP_Plan={setMyHealthID_OP_Plan}
                myHealthID_OP_CoIns={myHealthID_OP_CoIns}
                setMyHealthID_OP_CoIns={setMyHealthID_OP_CoIns}
                myHealthID_MA_Plan={myHealthID_MA_Plan}
                setMyHealthID_MA_Plan={setMyHealthID_MA_Plan}
                myHealthID_DN_Plan={myHealthID_DN_Plan}
                setMyHealthID_DN_Plan={setMyHealthID_DN_Plan}
                getCoInsLabel={getCoInsLabel_ID}
                areaOfCoverage={areaOfCoverage}
                countryOfResidence={countryOfResidence}
                specificAreaOfCoverage={specificAreaOfCoverage}
                setSpecificAreaOfCoverage={setSpecificAreaOfCoverage}
                setShowImageCopy={setShowImageCopy}
                selectedOptions={selectedOptions}
                addSelectedOption={addSelectedOption}
                setViewSelectedOption={setViewSelectedOption}
                specificCurrency={specificCurrency}
                setSpecificCurrency={setSpecificCurrency}
              />

              <MyHealthSingapore_Rates
                sx={{ display: displayStatus['my_health_singapore'] ? 'block' : 'none', mb: 2 }}
                my_health_singapore={my_health_singapore}
                clients={clients}
                currency={currency}
                selectedPlans={selectedPlans}
                setSelectedPlans={setSelectedPlans}
                loadingStatus={loadingStatus}
                setLoadingStatus={setLoadingStatus}
                response={response ? response.my_health_singapore : null}
                deductibles={deductibles}
                setDeductibles={setDeductibles}
                addToPendingTasks={addToPendingTasks}
                removeFromPendingTasks={removeFromPendingTasks}
                discounts={discounts}
                setDiscounts={setDiscounts}
                computedRates={computedRates}
                setComputedRates={setComputedRates}
                myHealth_HS_Plan={myHealthSG_HS_Plan}
                setMyHealth_HS_Plan={setMyHealthSG_HS_Plan}
                myHealth_HS_IP_Network={myHealthSG_HS_IP_Network}
                setMyHealth_HS_IP_Network={setMyHealthSG_HS_IP_Network}
                myHealth_HS_Deductible={myHealthSG_HS_Deductible}
                setMyHealth_HS_Deductible={setMyHealthSG_HS_Deductible}
                myHealth_OP_Plan={myHealthSG_OP_Plan}
                setMyHealth_OP_Plan={setMyHealthSG_OP_Plan}
                myHealth_OP_CoIns={myHealthSG_OP_CoIns}
                setMyHealth_OP_CoIns={setMyHealthSG_OP_CoIns}
                myHealth_MA_Plan={myHealthSG_MA_Plan}
                setMyHealth_MA_Plan={setMyHealthSG_MA_Plan}
                myHealth_DN_Plan={myHealthSG_DN_Plan}
                setMyHealth_DN_Plan={setMyHealthSG_DN_Plan}
                getCoInsLabel={getCoInsLabel_SG}
                areaOfCoverage={areaOfCoverage}
                countryOfResidence={countryOfResidence}
                specificAreaOfCoverage={specificAreaOfCoverage}
                setSpecificAreaOfCoverage={setSpecificAreaOfCoverage}
                setShowImageCopy={setShowImageCopy}
                selectedOptions={selectedOptions}
                addSelectedOption={addSelectedOption}
                setViewSelectedOption={setViewSelectedOption}
                specificCurrency={specificCurrency}
                setSpecificCurrency={setSpecificCurrency}
              />

              <MyHealthVietnam_Rates
                sx={{ display: displayStatus['my_health_vietnam'] ? 'block' : 'none', mb: 2 }}
                my_health_vietnam={my_health_vietnam}
                clients={clients}
                currency={currency}
                selectedPlans={selectedPlans}
                setSelectedPlans={setSelectedPlans}
                loadingStatus={loadingStatus}
                setLoadingStatus={setLoadingStatus}
                response={response ? response.my_health_vietnam : null}
                deductibles={deductibles}
                setDeductibles={setDeductibles}
                addToPendingTasks={addToPendingTasks}
                removeFromPendingTasks={removeFromPendingTasks}
                discounts={discounts}
                setDiscounts={setDiscounts}
                computedRates={computedRates}
                setComputedRates={setComputedRates}
                myHealth_HS_Plan={myHealthVN_HS_Plan}
                setMyHealth_HS_Plan={setMyHealthVN_HS_Plan}
                myHealth_HS_Deductible={myHealthVN_HS_Deductible}
                setMyHealth_HS_Deductible={setMyHealthVN_HS_Deductible}
                myHealth_OP_Plan={myHealthVN_OP_Plan}
                setMyHealth_OP_Plan={setMyHealthVN_OP_Plan}
                myHealth_OP_CoIns={myHealthVN_OP_CoIns}
                setMyHealth_OP_CoIns={setMyHealthVN_OP_CoIns}
                myHealth_MA_Plan={myHealthVN_MA_Plan}
                setMyHealth_MA_Plan={setMyHealthVN_MA_Plan}
                myHealth_DN_Plan={myHealthVN_DN_Plan}
                setMyHealth_DN_Plan={setMyHealthVN_DN_Plan}
                getCoInsLabel={getCoInsLabel_VN}
                areaOfCoverage={areaOfCoverage}
                countryOfResidence={countryOfResidence}
                specificAreaOfCoverage={specificAreaOfCoverage}
                setSpecificAreaOfCoverage={setSpecificAreaOfCoverage}
                setShowImageCopy={setShowImageCopy}
                selectedOptions={selectedOptions}
                addSelectedOption={addSelectedOption}
                setViewSelectedOption={setViewSelectedOption}
                specificCurrency={specificCurrency}
                setSpecificCurrency={setSpecificCurrency}
              />

              {
                deductibles['optimum_global'] &&
                <OptimumGlobal_Rates
                  sx={{ display: displayStatus['optimum_global'] ? 'block' : 'none', mb: 2 }}
                  clients={clients}
                  currency={currency}
                  selectedPlans={selectedPlans}
                  setSelectedPlans={setSelectedPlans}
                  loadingStatus={loadingStatus}
                  setLoadingStatus={setLoadingStatus}
                  response={response ? response.optimum_global : null}
                  deductibles={deductibles}
                  setDeductibles={setDeductibles}
                  addToPendingTasks={addToPendingTasks}
                  discounts={discounts}
                  setDiscounts={setDiscounts}
                  computedRates={computedRates}
                  setComputedRates={setComputedRates}
                  specificAreaOfCoverage={specificAreaOfCoverage}
                  setSpecificAreaOfCoverage={setSpecificAreaOfCoverage}
                  areaOfCoverage={areaOfCoverage}
                  setShowImageCopy={setShowImageCopy}
                  selectedOptions={selectedOptions}
                  addSelectedOption={addSelectedOption}
                  setViewSelectedOption={setViewSelectedOption}
                  specificCurrency={specificCurrency}
                  setSpecificCurrency={setSpecificCurrency}
                />
              }

              {
                appConfig.content.specifics['morgan_price'] &&
                <MorganPrice_Rates
                  sx={{ display: displayStatus['morgan_price'] ? 'block' : 'none', mb: 2 }}
                  clients={clients}
                  currency={currency}
                  selectedPlans={selectedPlans}
                  setSelectedPlans={setSelectedPlans}
                  loadingStatus={loadingStatus}
                  setLoadingStatus={setLoadingStatus}
                  response={response ? response.morgan_price : null}
                  deductibles={deductibles}
                  setDeductibles={setDeductibles}
                  addToPendingTasks={addToPendingTasks}
                  discounts={discounts}
                  setDiscounts={setDiscounts}
                  computedRates={computedRates}
                  setComputedRates={setComputedRates}
                  specificAreaOfCoverage={specificAreaOfCoverage}
                  setSpecificAreaOfCoverage={setSpecificAreaOfCoverage}
                  areaOfCoverage={areaOfCoverage}
                  setShowImageCopy={setShowImageCopy}
                  selectedOptions={selectedOptions}
                  addSelectedOption={addSelectedOption}
                  setViewSelectedOption={setViewSelectedOption}
                  specificCurrency={specificCurrency}
                  setSpecificCurrency={setSpecificCurrency}
                />
              }
            </Box>
          </Box>
        </Container>
      </Main>

      {/* Dialogs */}
      <AlertDialog title='Start New Quotation'
        positiveButtonLabel='Confirm' negativeButtonLabel='Cancel' open={clearQuotationConfirmation} setOpen={setClearQuotationConfirmation}
        callback={() => {
          setClearQuotationConfirmation(false);
          handleNewQuotation();
        }} />

      <AlertDialog
        open={showRemoveClient}
        setOpen={setShowRemoveClient}
        title='Remove Client'
        content='Please confirm to remove client'
        positiveButtonLabel='Remove'
        negativeButtonLabel='Cancel'
        callback={() => {
          handleRemoveClient(removeClientIndex);
          setShowRemoveClient(false);
        }} />

      {/* <AlertDialog title='Save Quotation Data' content='Do you want to save this data?'
        positiveButtonLabel='Yes' negativeButtonLabel='Cancel' open={showQuotationDataFormDialog} setOpen={setShowQuotationDataFormDialog}
        callback={() => {
          setShowQuotationDataFormDialog(false);
          handleSaveQuotationData();
        }} /> */}

      <QuotationDataFormDialog
        open={showQuotationDataFormDialog}
        setOpen={setShowQuotationDataFormDialog}
        refInputTable={refInputTable}
        quotationName={quotationName}
        setQuotationName={setQuotationName}
        quotationData={quotationData}
        mode={quotationDataFormDialogMode}
        callback={(quotationName) => {
          setShowQuotationDataFormDialog(false);
          handleSaveQuotationData(quotationName);
        }}
      />

      <Download_TOB_FormDialog
        open={showDownload_TOB_FormDialog}
        setOpen={setShowDownload_TOB_FormDialog}
        selectedPlans={filterSelectedInsurerPlan(['my_health_indonesia', 'my_health_singapore', 'my_health_vietnam'])}
        handleDownloadTOB={handleDownloadTOB}
        setRecommendedPlans={setRecommendedPlans}
        recommendedPlans={recommendedPlans}
        setExtraPlansForTOB={setExtraPlansForTOB}
        extraPlansForTOB={extraPlansForTOB}
        tobNotes={tobNotes}
        setTobNotes={setTobNotes}
        benefits={benefits}
        setBenefits={setBenefits}
        listBenefits={listBenefits}
        setListBenefits={setListBenefits}
        TOB_Data={TOB_Data}
        tableOfBenefits={tableOfBenefits}
        setTableOfBenefits={setTableOfBenefits}
        fetchingTOB_Data={fetchingTOB_Data}
        handleFetchTOB_Data={handleFetchTOB_Data}
        my_health_indonesia={my_health_indonesia}
        my_health_singapore={my_health_singapore}
        my_health_vietnam={my_health_vietnam}
        insurers_plans_map={insurers_plans_map}
        providedFor={providedFor}
        setProvidedFor={setProvidedFor}
        tobOutputType={tobOutputType}
        setTobOutputType={setTobOutputType}
      />

      <PremiumsSummaryDialog
        open={showPremiumsSummaryDialog}
        setOpen={setShowPremiumsSummaryDialog}
        clients={clients}
        response={response}
        selectedPlans={filterSelectedInsurerPlan()}
        currency={_currency}
        deductibles={deductibles}
        discounts={discounts}
        insurers_plans_map={insurers_plans_map}
        computedRates={computedRates}
        paymentFrequency={paymentFrequency}
        specificAreaOfCoverage={specificAreaOfCoverage}
      />


      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showFetchingRatesSnackbar}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        message="Please wait..."
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showImageCopy}
        autoHideDuration={1000}
        onClose={handleCloseSnackbarImageCopy}
        message="Image Copied to Clipboard"
      />
    </Box>
  )
}

export default Quotation1