import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import CustomSelect2 from './CustomSelect2';
import { Box, Typography, FormControlLabel, Checkbox, Grid, TextField, InputAdornment, Select, MenuItem, colors, Button } from '@mui/material';
import SelectPlanCheckbox from './SelectPlanCheckbox';
import { ConfigContext } from '../utils/ConfigContext';
import { AuthContext } from '../utils/AuthContext';
import TableRatesRow from './TableRatesRow';
import { getQuotation } from '../services/CompanyQuotationService';
import getSafe from '../utils/getSafe';
import domtoimage from 'dom-to-image';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const OptimumGlobal_Rates = forwardRef(({ sx, clients, loadingStatus, currency, selectedPlans, setSelectedPlans,
  response, deductibles, setDeductibles, addToPendingTasks,
  discounts, setDiscounts, computedRates, setComputedRates,
  specificAreaOfCoverage, setSpecificAreaOfCoverage, areaOfCoverage,
  setShowImageCopy, selectedOptions, addSelectedOption,
  setViewSelectedOption, specificCurrency, setSpecificCurrency }, ref) => {
  const { appConfig } = useContext(ConfigContext);

  const id = 'optimum_global';

  const mhd_group_loadings = getSafe(() => appConfig.content.specifics.optimum_global.mhd_group_loadings);
  const business_types = getSafe(() => appConfig.content.specifics.optimum_global.business_type);
  const quote_types = getSafe(() => appConfig.content.specifics.optimum_global.quote_type);
  const family_discounts = getSafe(() => appConfig.content.specifics.optimum_global.family_discount);
  const bmi_loadings = getSafe(() => appConfig.content.specifics.optimum_global.bmi_loadings);
  const out_patient_co_pay = getSafe(() => appConfig.content.specifics.optimum_global.out_patient_co_pay);
  const area_of_cover = getSafe(() => appConfig.content.specifics.optimum_global.area_of_cover);
  const in_patient_deductible = getSafe(() => appConfig.content.specifics.optimum_global.in_patient_deductible);
  const optimum_global_deductibles = getSafe(() => appConfig.content.deductibles.optimum_global, []);
  const payment_terms = getSafe(() => appConfig.content.specifics.optimum_global.payment_terms);
  const currency_rates = getSafe(() => appConfig.content.specifics.optimum_global.currency_rates);


  const [businessType, setBusinessType] = useState(getBusinessTypesLabel().length > 0 ? getBusinessTypesLabel()[0] : '');
  const [quoteType, setQuoteType] = useState(quote_types ? quote_types[0] : '');
  const [MHD_Group_Loading, set_MHD_Group_Loading] = useState(mhd_group_loadings ? get_MHD_Group_LoadingsLabel()[0] : '');
  const [familyDiscount, setFamilyDiscount] = useState(family_discounts ? getFamilyDiscountsLabel()[0] : '');
  const [bmiLoading, setBmiLoading] = useState(bmi_loadings ? getBmiLoadingsLabel()[0] : '');
  const [outpatientCoPay, setOutpatientCoPay] = useState(out_patient_co_pay ? getOutpatientCoPayLabels()[0] : '');


  const company_Plans = ['optimum_global_emerald', 'optimum_global_sapphire', 'optimum_global_ruby', 'optimum_global_jade', 'optimum_global_diamond'];

  const specific_area_of_coverages = getSafe(() => appConfig.content.optimum_global_area_of_coverage, []);

  const currencies = getSafe(() => appConfig.content.currencies, []);

  const [addOns, setAddOns] = useState({
    dental: false,
    maternity: false,
    optical: false,
  });

  const [assistanceCovers, setAssistanceCovers] = useState({
    evacuation: false,
    repatriation: false,
  });

  useEffect(() => {
    computedRates[id] = getComputedRates();
    setComputedRates(computedRates);
  }, [response, discounts, businessType, quoteType, familyDiscount, MHD_Group_Loading, bmiLoading, outpatientCoPay, deductibles[id]]);

  useEffect(() => {
    if (businessType === 'Individual') {
      set_MHD_Group_Loading('N/A');
    }

    if (businessType !== 'Individual') {
      setFamilyDiscount('N/A');
    }

  }, [businessType]);

  useEffect(() => {
    addSelectedOption(id, 'business_type', businessType);
    addSelectedOption(id, 'quote_type', quoteType);
    addSelectedOption(id, 'mhd_group_loading', MHD_Group_Loading);
    addSelectedOption(id, 'family_discount', familyDiscount);
    addSelectedOption(id, 'bmi_loadings', bmiLoading);
    addSelectedOption(id, 'outpatient_co_pay', outpatientCoPay);

    addSelectedOption(id, 'specific_area_of_coverage', specificAreaOfCoverage[id]);
    addSelectedOption(id, 'specific_currency', specificCurrency[id]);
    addSelectedOption(id, 'deductible', deductibles[id]);
    addSelectedOption(id, 'discount', discounts[id] == '' ? 0 : discounts[id]);
  }, [businessType, quoteType, familyDiscount, MHD_Group_Loading,
    bmiLoading, outpatientCoPay, specificAreaOfCoverage[id], specificCurrency[id], deductibles[id], discounts[id]]);

  useEffect(() => {
    setViewSelectedOption(id, 'business_type', setBusinessType);
    setViewSelectedOption(id, 'quote_type', setQuoteType);
    setViewSelectedOption(id, 'mhd_group_loading', set_MHD_Group_Loading);
    setViewSelectedOption(id, 'family_discount', setFamilyDiscount);
    setViewSelectedOption(id, 'bmi_loadings', setBmiLoading);
    setViewSelectedOption(id, 'outpatient_co_pay', setOutpatientCoPay);

    setViewSelectedOption(id, 'deductible', (option_value) => {
      deductibles[id] = option_value;
      setDeductibles(deductibles);
    });
    setViewSelectedOption(id, 'discount', (option_value) => {
      discounts[id] = option_value;
      setDiscounts(discounts);
    });

    setViewSelectedOption(id, 'specific_area_of_coverage', (option_value) => {
      specificAreaOfCoverage[id] = option_value;
      setSpecificAreaOfCoverage(specificAreaOfCoverage);
    });

    setViewSelectedOption(id, 'specific_currency', (option_value) => {
      specificCurrency[id] = option_value;
      setSpecificCurrency(specificCurrency);
    });
  }, [selectedOptions]);

  function getComputedRates() {
    if (!response) return;

    const rates = response.rates;
    const discount = discounts[id];
    const totalPercent = (100 - discount) * 0.01;

    const computedRates = [];

    for (let i = 0; i < rates.length; i++) {
      computedRates.push({
        'client': rates[i].client,
        'currency': rates[i].currency,
        'emerald': computedRate('emerald', rates[i].emerald) * totalPercent,
        'sapphire': computedRate('sapphire', rates[i].sapphire) * totalPercent,
        'ruby': computedRate('ruby', rates[i].ruby) * totalPercent,
        'jade': computedRate('jade', rates[i].jade) * totalPercent,
        'diamond': computedRate('diamond', rates[i].diamond) * totalPercent,
      })
    }

    return computedRates;
  }


  function checkIfThereIsSelected_Company_Plan() {
    for (let i = 0; i < selectedPlans.length; i++) {
      if (company_Plans.includes(selectedPlans[i])) {
        return true;
      }
    }

    return false;
  }

  function getBusinessTypesLabel() {
    const labels = [];

    for (let business_type in business_types) {
      labels.push(business_type);
    }

    return labels;
  }

  function getFamilyDiscountsLabel() {
    const labels = [];

    for (let family_discount in family_discounts) {
      labels.push(family_discount);
    }

    return labels;
  }

  function getBmiLoadingsLabel() {
    const labels = [];

    for (let bmi_loading in bmi_loadings) {
      labels.push(bmi_loading);
    }

    return labels;
  }

  function getOutpatientCoPayLabels() {
    const labels = [];

    for (let co_pay in out_patient_co_pay) {
      labels.push(co_pay);
    }

    return labels;
  }

  function get_MHD_Group_LoadingsLabel() {
    const labels = [];

    for (let mhd_group_loading in mhd_group_loadings) {
      labels.push(mhd_group_loading);
    }

    return labels;
  }

  function computeOptimumGlobalRates(rates) {
    const computedRates = [];

    for (let i = 0; i < rates.length; i++) {
      computedRates.push({
        'client': rates[i].client,
        'currency': rates[i].currency,
        'emerald': computedRate('emerald', rates[i].emerald),
        'sapphire': computedRate('sapphire', rates[i].sapphire),
        'ruby': computedRate('ruby', rates[i].ruby),
        'jade': computedRate('jade', rates[i].jade),
        'diamond': computedRate('diamond', rates[i].diamond)
      })
    }

    return computedRates;
  }

  function computedRate(plan, rate) {
    if (rate === 'Unavailable' || typeof rate === 'string') {
      return rate;
    }

    const currency = specificCurrency[id];

    if (currency === 'SGD') {
      return 'Unavailable';
    }

    const payment_frequency = clients[0]['payment_frequency'];

    if (payment_frequency === 'Monthly') {
      return 'Unavailable';
    }


    const out_patient_co_pay_rate = out_patient_co_pay[outpatientCoPay];
    const deductibleIndex = optimum_global_deductibles[currency].indexOf(deductibles[id]);
    const in_patient_deductible_rate = in_patient_deductible[deductibleIndex];

    const mhd_group_loadings_rate = mhd_group_loadings[MHD_Group_Loading];
    const bmi_loadings_rate = bmi_loadings[bmiLoading];
    const family_discount_rate = family_discounts[familyDiscount];

    const currency_rate = currency_rates[currency];



    const payment_term_rate = payment_terms[payment_frequency];

    let total = rate;

    if (['ruby', 'jade', 'diamond'].includes(plan)) {
      total = total * out_patient_co_pay_rate;
    }


    total = total * in_patient_deductible_rate;

    total = total + (total * mhd_group_loadings_rate);

    total = total + (total * bmi_loadings_rate);

    total = total * family_discount_rate;

    total = total * currency_rate;

    // optional rates
    if (['ruby', 'jade', 'diamond'].includes(plan)) {
      const optional_rates = response.optional_rates;

      let dental = optional_rates['dental'][plan];
      let maternity = optional_rates['maternity'][plan];
      let optical = optional_rates['optical'][plan];

      if (addOns.dental) {
        total += dental;
      }

      if (addOns.maternity) {
        total += maternity;
      }

      if (addOns.optical) {
        total += optical;
      }
    }

    if (payment_frequency === 'Semi Annually') {
      total /= 2;
    }

    if (payment_frequency === 'Quarterly') {
      total /= 4;
    }

    total = total * payment_term_rate;

    return total;
  }

  // function computedRate(plan, rate) {
  //   if (rate === 'Unavailable' || typeof rate === 'string') {
  //     return rate;
  //   }

  //   const MHD_LoadingValue = MHD_Loadings[MHD_Loading];
  //   const companyDiscountValue = companyDiscounts[companyDiscount][plan];
  //   const groupSizeValue = groupSizes[groupSize];

  //   let total = rate;

  //   // MHD loadings
  //   total = total * (1 + MHD_LoadingValue);

  //   // company discount
  //   total = total * (1 - companyDiscountValue);

  //   // group size discount
  //   total = total * (1 - groupSizeValue);

  //   return total;
  // }

  // function getCompanyDiscountLabel(plan) {
  //   const val = companyDiscounts[companyDiscount][plan];

  //   return (val * 100).toFixed(0) + '%'
  // }

  function onInputDiscountChange(e) {
    var numericValue = e.target.value.replace(/[^0-9]/g, '');

    // Remove leading zeros
    numericValue = numericValue.replace(/^0+/, '');

    if (numericValue > 100) {
      numericValue = 100;
    }

    discounts[id] = numericValue;

    setDiscounts({ ...discounts });
  }

  function getAdditionalColumns(plan) {
    if (!response) {

      const placeholder = [];

      if (addOns.dental) {
        placeholder.push({
          value: 'N/A'
        });
      }

      if (addOns.maternity) {
        placeholder.push({
          value: 'N/A'
        });
      }

      if (addOns.optical) {
        placeholder.push({
          value: 'N/A'
        });
      }


      return placeholder;
    }

    const currency = specificCurrency[id];

    // if (currency === 'SGD') {
    //   return [];
    // }

    const optional_rates = response.optional_rates;

    let dental = optional_rates['dental'][plan];
    let maternity = optional_rates['maternity'][plan];
    let optical = optional_rates['optical'][plan];

    const payment_frequency = clients[0]['payment_frequency'];
    const payment_term_rate = payment_terms[payment_frequency];

    const currency_rate = currency_rates[currency];

    if (payment_frequency === 'Monthly') {
      dental = 'Unavailable';
      maternity = 'Unavailable';
      optical = 'Unavailable';
    }

    dental *= currency_rate;
    maternity *= currency_rate;
    optical *= currency_rate;


    if (payment_frequency === 'Semi Annually') {
      dental /= 2;
      maternity /= 2;
      optical /= 2;

      dental *= payment_term_rate;
      maternity *= payment_term_rate;
      optical *= payment_term_rate;
    }

    if (payment_frequency === 'Quarterly') {
      dental /= 4;
      maternity /= 4;
      optical /= 4;

      dental *= payment_term_rate;
      maternity *= payment_term_rate;
      optical *= payment_term_rate;
    }

    const columns = [];

    if (addOns.dental) {
      columns.push({ value: dental ? formatOptionalRate(dental) : 'N/A', textAlign: 'center', bgColor: '#4dd0e1' });
    }

    if (addOns.maternity) {
      columns.push({ value: maternity ? formatOptionalRate(maternity) : 'N/A', textAlign: 'center', bgColor: '#4dd0e1' });
    }

    if (addOns.optical) {
      columns.push({ value: optical ? formatOptionalRate(optical) : 'N/A', textAlign: 'center', bgColor: '#4dd0e1' });
    }

    return columns;
  }

  function formatOptionalRate(rate) {
    if (isNaN(rate) || rate == 'Unavailable' || typeof rate === 'string') {
      return <span>{'N/A'}</span>
    }

    if (isNaN(rate)) {
      return rate;
    }

    return getCurrencySign() + rate.toLocaleString('en-PH', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  function getCurrencySign() {
    const currency = specificCurrency[id];

    if (currency === 'USD') {
      return '$';
    } else if (currency === 'EURO') {
      return '€';
    } else if (currency === 'GBP') {
      return '£';
    } else if (currency === 'SGD') {
      return 'SGD';
    }

    return '';
  }

  function getAreaOfCoverage() {
    if (!area_of_cover) return '';


    let generalAreaOfCoverage = areaOfCoverage;

    if (specificAreaOfCoverage[id] === 'parent') {
      generalAreaOfCoverage = areaOfCoverage;
    } else {
      generalAreaOfCoverage = specificAreaOfCoverage[id];
    }

    const specificArea = area_of_cover[generalAreaOfCoverage] || '';

    return specificArea;
  }

  return (
    <Box sx={sx}>
      <Paper sx={{ borderColor: '#e0e0e0', borderWidth: 2, pb: 2 }} variant='outlined' >
        <Typography variant='h5' sx={{ color: '#fff', background: '#00bcd4', px: 2, py: 1 }}>AXA - Optimum Global</Typography>

        <Box sx={{ p: 2 }}>

          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography fontSize={18} fontWeight={600}>Plan Type</Typography>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='optimum_global_emerald' />} label="Emerald" />
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='optimum_global_sapphire' />} label="Sapphire" />
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='optimum_global_ruby' />} label="Ruby" />
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='optimum_global_jade' />} label="Jade" />
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='optimum_global_diamond' />} label="Diamond" />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography fontSize={18} fontWeight={600}>Add ons</Typography>
              <FormControlLabel control={<Checkbox checked={addOns.dental}
                onChange={(e) => { setAddOns({ ...addOns, dental: e.target.checked }) }} />}
                label="Dental" />
              <FormControlLabel control={<Checkbox checked={addOns.maternity}
                onChange={(e) => { setAddOns({ ...addOns, maternity: e.target.checked }) }} />}
                label="Maternity" />
              <FormControlLabel control={<Checkbox checked={addOns.optical}
                onChange={(e) => { setAddOns({ ...addOns, optical: e.target.checked }) }} />}
                label="Optical" />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Business Type</Typography>
                <CustomSelect2
                  sx={{ minWidth: 190 }}
                  value={businessType}
                  onChange={(e) => {
                    setBusinessType(e.target.value);
                  }}
                  items={getBusinessTypesLabel()}
                  disabled={loadingStatus[id]}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Quote Type</Typography>
                <CustomSelect2
                  sx={{ minWidth: 190 }}
                  value={quoteType}
                  onChange={(e) => {
                    setQuoteType(e.target.value);
                  }}
                  items={quote_types}
                  disabled={loadingStatus[id]}
                />
              </Box>
              {
                businessType !== 'Individual' &&
                <Box sx={{ display: 'flex', alignItems: 'start' }}>
                  <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>MHD Group Loadings</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <CustomSelect2
                      sx={{ minWidth: 190 }}
                      value={MHD_Group_Loading}
                      onChange={(e) => {
                        set_MHD_Group_Loading(e.target.value);
                      }}
                      items={get_MHD_Group_LoadingsLabel()}
                      disabled={loadingStatus[id]}
                    />
                    <Typography fontSize={18}>
                      <span display='inline-block' style={{ fontSize: 18, color: '#00bcd4', fontWeight: 600 }}>{mhd_group_loadings ? mhd_group_loadings[MHD_Group_Loading] * 100 : 0}%</span>
                    </Typography>
                  </Box>
                </Box>
              }

              {
                businessType === 'Individual' &&
                <Box sx={{ display: 'flex', alignItems: 'start' }}>
                  <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Family Discount</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <CustomSelect2
                      sx={{ minWidth: 190 }}
                      value={familyDiscount}
                      onChange={(e) => {
                        setFamilyDiscount(e.target.value);
                      }}
                      items={getFamilyDiscountsLabel()}
                      disabled={loadingStatus[id]}
                    />
                    <Typography fontSize={18}>
                      <span display='inline-block' style={{ fontSize: 18, color: '#00bcd4', fontWeight: 600 }}>{family_discounts ? (100 - (family_discounts[familyDiscount] * 100)) : 0}%</span>
                    </Typography>
                  </Box>
                </Box>
              }
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>BMI Loadings</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <CustomSelect2
                    sx={{ minWidth: 190 }}
                    value={bmiLoading}
                    onChange={(e) => {
                      setBmiLoading(e.target.value);
                    }}
                    items={getBmiLoadingsLabel()}
                    disabled={loadingStatus[id]}
                  />
                  <Typography fontSize={18}>
                    <span display='inline-block' style={{ fontSize: 18, color: '#00bcd4', fontWeight: 600 }}>{bmi_loadings ? (bmi_loadings[bmiLoading] * 100) : 0}%</span>
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Area of Coverage</Typography>
                <Select
                  size='small'
                  value={specificAreaOfCoverage[id]}
                  onChange={(e) => {
                    specificAreaOfCoverage[id] = e.target.value;
                    setSpecificAreaOfCoverage({ ...specificAreaOfCoverage });

                    addToPendingTasks([id]);
                  }}
                  sx={{ fontSize: 12, height: 40, width: '190px' }}
                  fullWidth
                  disabled={loadingStatus[id]}
                >
                  {/* <MenuItem value={'parent'} sx={{ color: colors.blue[500] }}>{areaOfCoverage}</MenuItem> */}
                  {
                    specific_area_of_coverages && specific_area_of_coverages.map(area_of_coverage => (
                      <MenuItem key={area_of_coverage} value={area_of_coverage}>{area_of_coverage}</MenuItem>
                    ))
                  }

                </Select>


              </Box>

              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Currency</Typography>
                <Select
                  size='small'
                  value={specificCurrency[id]}
                  onChange={(e) => {
                    specificCurrency[id] = e.target.value;
                    setSpecificCurrency({ ...specificCurrency });

                    // clearResponse();
                    
                    setDeductibles(prev => {
                      return { 
                        ...prev, 
                        [id]: 'Nil Deductible'
                      };
                    });
                    

                    addToPendingTasks([id]);
                  }}
                  sx={{ fontSize: 12, height: 40, width: '190px', minWidth: 190 }}
                  fullWidth
                  disabled={loadingStatus[id]}
                >
                  {
                    currencies && currencies.map(currency => (
                      <MenuItem key={currency} value={currency}>{currency}</MenuItem>
                    ))
                  }

                </Select>
              </Box>
              {/* <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Area of Coverage:</Typography>


                <Typography fontSize={18}>
                  <span display='inline-block' style={{ fontSize: 18, color: '#00bcd4', fontWeight: 600 }}>{getAreaOfCoverage()}</span>
                </Typography>

              </Box> */}
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Inpatient Deductible</Typography>
                <CustomSelect2
                  sx={{ minWidth: 190 }}
                  value={deductibles[id]}
                  onChange={(e) => {
                    // setDeductible(e.target.value);

                    // setDeductibleIndex(getDeductibleIndex(e.target.value));

                    const val = e.target.value;

                    const _deductibles = JSON.parse(JSON.stringify(deductibles));

                    _deductibles[id] = val;
                    // _deductibles[id].deductible_index = getDeductibleIndex(val);

                    setDeductibles(_deductibles);

                    // addToPendingTasks([id]);

                    // handleUpdateLoadingStatus(true);
                    // setLoading(true);
                    // setTimeout(() => {
                    //   ref.current.handleGetQuotation();
                    // }, 200);

                  }}
                  items={optimum_global_deductibles ? optimum_global_deductibles[specificCurrency[id]] : []}
                  disabled={loadingStatus[id]}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Outpatient Co-Pay Option</Typography>
                <CustomSelect2
                  sx={{ minWidth: 190 }}
                  value={outpatientCoPay}
                  onChange={(e) => {
                    setOutpatientCoPay(e.target.value)
                  }}
                  items={getOutpatientCoPayLabels()}
                  disabled={loadingStatus[id]}
                  isZeroDeductibleUniform={false}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Renewal/Further Discount</Typography>
                <TextField
                  size='small'
                  sx={{ width: '80px', textAlign: 'end' }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  value={discounts[id]}
                  onChange={onInputDiscountChange}
                  autoComplete="off"
                />
              </Box>
            </Box>
          </Box>

          <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
            <Button
              size='small'
              sx={{ mb: 1 }}
              onClick={() => {
                var node = document.getElementById('table-optimum-global-rates');

                node.style = 'width: auto;';

                domtoimage.toBlob(node).then(function (blob) {
                  // Create ClipboardItem with blob and its type, and add to an array
                  const data = [new ClipboardItem({ [blob.type]: blob })];
                  // Write the data to the clipboard
                  navigator.clipboard.write(data);

                  setShowImageCopy(true);

                  node.style = 'width: 100%;';
                })
                  .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                    node.style = 'width: 100%;';
                  });
              }}
              endIcon={<ContentCopyIcon />}
            >Copy Image</Button>
          </div>

          <TableContainer component={Paper}>
            <Table id='table-optimum-global-rates' size='small' sx={{ minWidth: 650 }}>
              <TableHead sx={{ background: '#00bcd4', color: '#fff' }}>
                <TableRow>
                  <TableCell sx={{ width: 120, color: '#fff', fontWeight: 600 }}>Plan</TableCell>

                  {
                    addOns.dental && <TableCell sx={{ width: 60, color: '#fff', fontWeight: 600 }}>Dental</TableCell>
                  }

                  {
                    addOns.maternity && <TableCell sx={{ width: 60, color: '#fff', fontWeight: 600 }}>Maternity</TableCell>
                  }


                  {
                    addOns.optical && <TableCell sx={{ width: 60, color: '#fff', fontWeight: 600 }}>Optical</TableCell>
                  }


                  {
                    clients.map((client, idx) => {
                      if (client.full_name.trim() === '') {
                        return <TableCell sx={{ width: 200, fontWeight: 600, color: '#fff' }} key={idx}>_</TableCell>
                      }

                      return <TableCell sx={{ width: 200, color: '#fff', fontWeight: 600 }} key={idx}><div>{client.full_name} ({client.age})</div><div>{client.country_of_residence}</div>  </TableCell>
                    })
                  }
                  <TableCell sx={{ color: '#fff', fontWeight: 600 }}>Subtotal</TableCell>
                </TableRow>

              </TableHead>
              <TableBody>
                <TableRatesRow
                  planId='optimum_global_emerald'
                  planName='emerald'
                  planLabel='Emerald'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#80deea'
                  loading={loadingStatus[id]}
                  rates={response ? computeOptimumGlobalRates(response.rates) : []}
                  currency={currency}
                  additionalColumns={getAdditionalColumns('emerald')}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('optimum_global_emerald')}
                />
                <TableRatesRow
                  planId='optimum_global_sapphire'
                  planName='sapphire'
                  planLabel='Sapphire'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#80deea'
                  loading={loadingStatus[id]}
                  rates={response ? computeOptimumGlobalRates(response.rates) : []}
                  currency={currency}
                  additionalColumns={getAdditionalColumns('sapphire')}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('optimum_global_sapphire')}
                />
                <TableRatesRow
                  planId='optimum_global_ruby'
                  planName='ruby'
                  planLabel='Ruby'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#80deea'
                  loading={loadingStatus[id]}
                  rates={response ? computeOptimumGlobalRates(response.rates) : []}
                  currency={currency}
                  additionalColumns={getAdditionalColumns('ruby')}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('optimum_global_ruby')}
                />
                <TableRatesRow
                  planId='optimum_global_jade'
                  planName='jade'
                  planLabel='Jade'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#80deea'
                  loading={loadingStatus[id]}
                  rates={response ? computeOptimumGlobalRates(response.rates) : []}
                  currency={currency}
                  additionalColumns={getAdditionalColumns('jade')}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('optimum_global_jade')}
                />
                <TableRatesRow
                  planId='optimum_global_diamond'
                  planName='diamond'
                  planLabel='Diamond'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#80deea'
                  loading={loadingStatus[id]}
                  rates={response ? computeOptimumGlobalRates(response.rates) : []}
                  currency={currency}
                  additionalColumns={getAdditionalColumns('diamond')}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('optimum_global_diamond')}
                />
                {
                  (assistanceCovers.evacuation || assistanceCovers.repatriation) &&
                  <TableRow>
                    <TableCell colSpan={clients.length + 3} sx={{ fontWeight: 600 }}>Optional</TableCell>
                  </TableRow>
                }

                {/* <TableRow>
                  <TableCell colSpan={clients.length + 3} sx={{ fontWeight: 600 }}>Optional</TableCell>
                </TableRow> */}

                {/* <TableRatesRow
                  planId='company_evacuation'
                  planName='evacuation'
                  planLabel='Evacuation (Annually)'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#eeeeee'
                  loading={loadingStatus[id]}
                  rates={response ? response.assistance_covers : []}
                  currency={currency}
                  planLabelColSpan={2}
                  visible={assistanceCovers.evacuation}
                />

                <TableRatesRow
                  planId='company_repatriation'
                  planName='repatriation'
                  planLabel='Repatriation (Annually)'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#eeeeee'
                  loading={loadingStatus[id]}
                  rates={response ? response.assistance_covers : []}
                  currency={currency}
                  planLabelColSpan={2}
                  visible={assistanceCovers.repatriation}
                /> */}
                {/* {
                  checkIfThereIsSelected_Company_Plan() == false &&
                  <TableRow>
                    <TableCell>No Plan Selected</TableCell>
                  </TableRow>
                } */}
              </TableBody>
            </Table>

          </TableContainer>


        </Box>


      </Paper>

    </Box>
  );
});

export default OptimumGlobal_Rates;