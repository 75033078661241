import { Accordion, AccordionDetails, AccordionSummary, Box, MenuItem, Paper, Select, Typography } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SettingsIcon from '@mui/icons-material/Settings';
import { useEffect, useState } from "react";

export default function RatesSettings({ sx, insurerRates, selectedRatesVersion, setSelectedRatesVersion }) {
  const [lifelineRates, setLifelineRates] = useState([]);

  const [lifelineAssistanceCoverRates, setLifelineAssistanceCoverRates] = useState([]);

  const [bwhoRates, setBwhoRates] = useState([]);

  const [bwhoAssistanceCoverRates, setBwhoAssistanceCoverRates] = useState([]);

  const [companyRates, setCompanyRates] = useState([]);

  const [companyAssistanceCoverRates, setCompanyAssistanceCoverRates] = useState([]);

  const [oilGasRates, setOilGasRates] = useState([]);

  const [firstChoiceRates, setFirstChoiceRates] = useState([]);

  const [ihhpRates, setIhhpRates] = useState([]);

  const [vumiRates, setVumiRates] = useState([]);

  const [myHealthIndonesiaRates, setMyHealthIndonesiaRates] = useState([]);

  const [myHealthSingaporeRates, setMyHealthSingaporeRates] = useState([]);

  const [myHealthVietnamRates, setMyHealthVietnamRates] = useState([]);

  const [optimumGlobalRates, setOptimumGlobalRates] = useState([]);

  const [morganPriceRates, setMorganPriceRates] = useState([]);

  useEffect(() => {
    setLifelineRates(getAllRatesByInsurer('lifeline_rates'));

    setLifelineAssistanceCoverRates(getAllRatesByInsurer('lifeline_assistance_cover_rates'));

    setBwhoRates(getAllRatesByInsurer('bwho_rates'));

    setBwhoAssistanceCoverRates(getAllRatesByInsurer('bwho_assistance_cover_rates'));

    setCompanyRates(getAllRatesByInsurer('company_rates'));

    setCompanyAssistanceCoverRates(getAllRatesByInsurer('company_assistance_cover_rates'));

    setOilGasRates(getAllRatesByInsurer('oil_gas_rates'));

    setFirstChoiceRates(getAllRatesByInsurer('first_choice_rates'));

    setIhhpRates(getAllRatesByInsurer('ihhp_rates'));

    setVumiRates(getAllRatesByInsurer('vumi_rates'));

    setMyHealthIndonesiaRates(getAllRatesByInsurer('my_health_indonesia_rates'));

    setMyHealthSingaporeRates(getAllRatesByInsurer('my_health_singapore_rates'));

    setMyHealthVietnamRates(getAllRatesByInsurer('my_health_vietnam_rates'));

    setOptimumGlobalRates(getAllRatesByInsurer('optimum_global_rates'));

    setMorganPriceRates(getAllRatesByInsurer('morgan_price_rates'));

    if (selectedRatesVersion.lifeline === '') {
      selectedRatesVersion.lifeline = getRatesLatestVersion('lifeline_rates');
    }

    if (selectedRatesVersion.lifeline_assistance_cover === '') {
      selectedRatesVersion.lifeline_assistance_cover = getRatesLatestVersion('lifeline_assistance_cover_rates');
    }

    if (selectedRatesVersion.bwho === '') {
      selectedRatesVersion.bwho = getRatesLatestVersion('bwho_rates');
    }

    if (selectedRatesVersion.bwho_assistance_cover === '') {
      selectedRatesVersion.bwho_assistance_cover = getRatesLatestVersion('bwho_assistance_cover_rates');
    }

    if (selectedRatesVersion.company === '') {
      selectedRatesVersion.company = getRatesLatestVersion('company_rates');
    }

    if (selectedRatesVersion.company_assistance_cover === '') {
      selectedRatesVersion.company_assistance_cover = getRatesLatestVersion('company_assistance_cover_rates');
    }

    if (selectedRatesVersion.oil_gas === '') {
      selectedRatesVersion.oil_gas = getRatesLatestVersion('oil_gas_rates');
    }

    if (selectedRatesVersion.first_choice === '') {
      selectedRatesVersion.first_choice = getRatesLatestVersion('first_choice_rates');
    }

    if (selectedRatesVersion.ihhp === '') {
      selectedRatesVersion.ihhp = getRatesLatestVersion('ihhp_rates');
    }

    if (selectedRatesVersion.vumi === '') {
      selectedRatesVersion.vumi = getRatesLatestVersion('vumi_rates');
    }

    if (selectedRatesVersion.my_health_indonesia === '') {
      selectedRatesVersion.my_health_indonesia = getRatesLatestVersion('my_health_indonesia_rates');
    }

    if (selectedRatesVersion.my_health_singapore === '') {
      selectedRatesVersion.my_health_singapore = getRatesLatestVersion('my_health_singapore_rates');
    }

    if (selectedRatesVersion.my_health_vietnam === '') {
      selectedRatesVersion.my_health_vietnam = getRatesLatestVersion('my_health_vietnam_rates');
    }

    if (selectedRatesVersion.optimum_global === '') {
      selectedRatesVersion.optimum_global = getRatesLatestVersion('optimum_global_rates');
    }

    if (selectedRatesVersion.morgan_price === '') {
      selectedRatesVersion.morgan_price = getRatesLatestVersion('morgan_price_rates');
    }

    setSelectedRatesVersion({...selectedRatesVersion});

  }, [insurerRates]);

  function getAllRatesByInsurer(insurer) {
    const rates = [];

    for (let i = 0; i < insurerRates.length; i++) {
      if (insurerRates[i].insurer === insurer) {
        rates.push(insurerRates[i]);
      }
    }

    return rates;
  }

  function getRatesLatestVersion(insurer) {
    const insurerRate = getRatesLatest(insurer);

    if (!insurerRate) return '';

    return insurerRate.version;
  }

  function getRatesLatest(insurer) {
    for (let i = 0; i < insurerRates.length; i++) {
      if (insurerRates[i].insurer === insurer && insurerRates[i].is_latest) {
        return insurerRates[i];
      }
    }
  }

  const InsurerRatesSetting = function ({ sx, id, label, rates, selectedRatesVersion, setSelectedRatesVersion }) {
    return <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, ...sx }}>
      <Typography sx={{ width: 150 }}>{label}</Typography>
      <Select
        id={id + "-rates-select"}
        size='small'
        sx={{ ml: 2, fontSize: 14 }}
        value={selectedRatesVersion[id]}
        onChange={(e) => {
          selectedRatesVersion[id] = e.target.value
          setSelectedRatesVersion({
            ...selectedRatesVersion
          });
        }}
      >
        {
          rates.map(rate => {
            return <MenuItem
              key={rate.id}
              value={rate.version}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* <CheckCircleIcon sx={{ mr: 1, color: '#4caf50', fontSize: 18 }} /> */}
                {rate.version} { rate.is_latest ? '(Latest)' : '' }
              </Box>
            </MenuItem>
          })
        }

      </Select>
    </Box>
  }


  return (
    <Paper elevation={1} sx={sx}>
      <Accordion sx={{ border: 'none', backgroundColor: '#cfd8dc' }} variant='outlined'>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="rates-settings"
          id="rates-settings-header"

        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SettingsIcon sx={{ color: '#bdbdbd', mr: 1, fontSize: 24 }} />
            <Typography sx={{ color: '#212121', fontSize: 14 }}>Rates Settings</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: '#fff' }}>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mr: 4 }}>
              <InsurerRatesSetting
                id='lifeline'
                label='BUPA Lifeline'
                rates={lifelineRates}
                selectedRatesVersion={selectedRatesVersion}
                setSelectedRatesVersion={setSelectedRatesVersion}
              />

              <InsurerRatesSetting
                id='lifeline_assistance_cover'
                label='BUPA Lifeline Assistance Cover'
                rates={lifelineAssistanceCoverRates}
                selectedRatesVersion={selectedRatesVersion}
                setSelectedRatesVersion={setSelectedRatesVersion}
              />

              <InsurerRatesSetting
                id='bwho'
                label='BWHO'
                rates={bwhoRates}
                selectedRatesVersion={selectedRatesVersion}
                setSelectedRatesVersion={setSelectedRatesVersion}
              />

              <InsurerRatesSetting
                id='bwho_assistance_cover'
                label='BWHO Assistance Cover'
                rates={bwhoAssistanceCoverRates}
                selectedRatesVersion={selectedRatesVersion}
                setSelectedRatesVersion={setSelectedRatesVersion}
              />

              <InsurerRatesSetting
                id='company'
                label='BUPA Company'
                rates={companyRates}
                selectedRatesVersion={selectedRatesVersion}
                setSelectedRatesVersion={setSelectedRatesVersion}
              />

              <InsurerRatesSetting
                id='company_assistance_cover'
                label='BUPA Company Assistance Cover'
                rates={companyAssistanceCoverRates}
                selectedRatesVersion={selectedRatesVersion}
                setSelectedRatesVersion={setSelectedRatesVersion}
              />

              <InsurerRatesSetting
                id='oil_gas'
                label='Oil & Gas'
                rates={oilGasRates}
                selectedRatesVersion={selectedRatesVersion}
                setSelectedRatesVersion={setSelectedRatesVersion}
              />

              <InsurerRatesSetting
                id='first_choice'
                label='First Choice'
                rates={firstChoiceRates}
                selectedRatesVersion={selectedRatesVersion}
                setSelectedRatesVersion={setSelectedRatesVersion}
              />

              <InsurerRatesSetting
                id='ihhp'
                label='IHHP'
                rates={ihhpRates}
                selectedRatesVersion={selectedRatesVersion}
                setSelectedRatesVersion={setSelectedRatesVersion}
              />



            </Box>

            <Box>
              <InsurerRatesSetting
                id='vumi'
                label='VUMI'
                rates={vumiRates}
                selectedRatesVersion={selectedRatesVersion}
                setSelectedRatesVersion={setSelectedRatesVersion}
              />

              <InsurerRatesSetting
                id='my_health_indonesia'
                label='MyHealth Indonesia'
                rates={myHealthIndonesiaRates}
                selectedRatesVersion={selectedRatesVersion}
                setSelectedRatesVersion={setSelectedRatesVersion}
              />

              <InsurerRatesSetting
                id='my_health_singapore'
                label='MyHealth Singapore'
                rates={myHealthSingaporeRates}
                selectedRatesVersion={selectedRatesVersion}
                setSelectedRatesVersion={setSelectedRatesVersion}
              />

              <InsurerRatesSetting
                id='my_health_vietnam'
                label='MyHealth Vietnam'
                rates={myHealthVietnamRates}
                selectedRatesVersion={selectedRatesVersion}
                setSelectedRatesVersion={setSelectedRatesVersion}
              />

              <InsurerRatesSetting
                id='optimum_global'
                label='Optimum Global'
                rates={optimumGlobalRates}
                selectedRatesVersion={selectedRatesVersion}
                setSelectedRatesVersion={setSelectedRatesVersion}
              />

              <InsurerRatesSetting
                id='morgan_price'
                label='Morgan Price'
                rates={morganPriceRates}
                selectedRatesVersion={selectedRatesVersion}
                setSelectedRatesVersion={setSelectedRatesVersion}
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
}



