import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import CustomSelect2 from './CustomSelect2';
import { Box, Typography, FormControlLabel, Grid, TextField, InputAdornment, Button, IconButton, Select, MenuItem, colors, Checkbox } from '@mui/material';
import SelectPlanCheckbox from './SelectPlanCheckbox';
import { ConfigContext } from '../utils/ConfigContext';
import TableRatesRow from './TableRatesRow';
import getSafe from '../utils/getSafe';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import domtoimage from 'dom-to-image';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const MorganPrice_Rates = forwardRef(({ sx, clients, loadingStatus, currency, selectedPlans, setSelectedPlans,
  response, deductibles, setDeductibles, addToPendingTasks,
  discounts, setDiscounts, computedRates, setComputedRates,
  specificAreaOfCoverage, setSpecificAreaOfCoverage, areaOfCoverage,
  setShowImageCopy, selectedOptions, addSelectedOption,
  setViewSelectedOption, specificCurrency, setSpecificCurrency }, ref) => {
  const { appConfig } = useContext(ConfigContext);

  const id = 'morgan_price';

  const excess_in_patient_treatment = getSafe(() => appConfig.content.specifics.morgan_price.excess_in_patient_treatment);
  const payment_frequency_rates = getSafe(() => appConfig.content.specifics.morgan_price.payment_frequency_rates);
  const area_of_coverages = getSafe(() => appConfig.content.specifics.morgan_price.area_of_coverages, []);

  const [excessInPatientTreatment, setExcessInPatientTreatment] = useState(excess_in_patient_treatment ? getExcessInPatientTreatmentsLabel()[0] : '');

  const plans = ['vumi_basic', 'vumi_standard', 'vumi_superior', 'vumi_ultra', 'vumi_total'];

  const specific_area_of_coverages = getSafe(() => appConfig.content.morgan_price_area_of_coverage);

  const currencies = getSafe(() => appConfig.content.currencies, []);


  const [optionalCovers, setOptionalCovers] = useState({
    op_module_1: false,
    op_module_2: false,
    remove_op_co_insurance: false,
    reduce_overall_annual_limit: false,
    dental: false,
    maternity_module: false,
    enhanced_network_module: false,
  });

  const tableRowRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    const parentTable = tableRowRef.current.closest('table');

    if (parentTable) {
      // Select all TableRow elements inside the TableBody
      const rows = document.querySelectorAll('#' + parentTable.id + ' .MuiTableBody-root .MuiTableRow-root');

      // Calculate the maximum height
      let max = 0;
      rows.forEach(row => {
        const height = row.offsetHeight;
        if (height > max) {
          max = height;
        }
      });

      setMaxHeight(max);
    }
  }, [response, optionalCovers]);


  useEffect(() => {
    computedRates[id] = getComputedRates();
    setComputedRates(computedRates);

  }, [response, discounts, excessInPatientTreatment, optionalCovers]);

  useEffect(() => {
    addSelectedOption(id, 'excess_inpatient_treatment', excessInPatientTreatment);
    addSelectedOption(id, 'specific_area_of_coverage', specificAreaOfCoverage[id]);
    addSelectedOption(id, 'specific_currency', specificCurrency[id]);
    addSelectedOption(id, 'discount', discounts[id] == '' ? 0 : discounts[id]);
  }, [excessInPatientTreatment, specificAreaOfCoverage[id], specificCurrency[id], discounts[id]]);

  useEffect(() => {
    setViewSelectedOption(id, 'excess_inpatient_treatment', setExcessInPatientTreatment);

    setViewSelectedOption(id, 'discount', (option_value) => {
      discounts[id] = option_value;
      setDiscounts(discounts);
    });

    setViewSelectedOption(id, 'specific_area_of_coverage', (option_value) => {
      specificAreaOfCoverage[id] = option_value;
      setSpecificAreaOfCoverage(specificAreaOfCoverage);
    });

    setViewSelectedOption(id, 'specific_currency', (option_value) => {
      specificCurrency[id] = option_value;
      setSpecificCurrency(specificCurrency);
    });
  }, [selectedOptions]);


  function getComputedRates() {
    if (!response) return;

    const rates = response.rates;
    const discount = discounts[id];
    const totalPercent = (100 - discount) * 0.01;

    const computedRates = [];

    for (let i = 0; i < rates.length; i++) {
      computedRates.push({
        'client': rates[i].client,
        'currency': rates[i].currency,
        'core_ip': computeSubtotalRate(rates[i]) * totalPercent,
        'plan_label': getPlanLabel()
      })
    }

    return computedRates;
  }

  function computeSubtotalRate(rate) {
    let total = computedRate(rate.core_ip, rate.client.payment_frequency);

    if (optionalCovers.op_module_1) {
      total += computedRate(rate.op_module_1, rate.client.payment_frequency);
    }

    if (optionalCovers.op_module_2) {
      total += computedRate(rate.op_module_2, rate.client.payment_frequency);
    }

    if (optionalCovers.remove_op_co_insurance) {
      total += computedRate(rate.remove_op_co_insurance, rate.client.payment_frequency);
    }

    if (optionalCovers.reduce_overall_annual_limit) {
      total += computedRate(rate.reduce_overall_annual_limit, rate.client.payment_frequency);
    }

    if (optionalCovers.dental) {
      total += computedRate(rate.dental, rate.client.payment_frequency);
    }

    if (optionalCovers.maternity_module) {
      total += computedRate(rate.maternity_module, rate.client.payment_frequency);
    }

    if (optionalCovers.enhanced_network_module) {
      total += computedRate(rate.enhanced_network_module, rate.client.payment_frequency);
    }

    return total;
  }

  function getPlanLabel() {
    let label = 'Core IP';

    if (optionalCovers.op_module_1) {
      label += ' + OP Module 1';
    }

    if (optionalCovers.op_module_2) {
      label += ' + OP Module 2';
    }

    if (optionalCovers.remove_op_co_insurance) {
      label += ' + Remove OP Co-Insurance';
    }

    if (optionalCovers.reduce_overall_annual_limit) {
      label += ' + Reduce Overall Annual Limit to $250,000';
    }

    if (optionalCovers.dental) {
      label += ' + Dental';
    }

    if (optionalCovers.maternity_module) {
      label += ' + Maternity Module';
    }

    if (optionalCovers.enhanced_network_module) {
      label += ' + Enhanced Network Module';
    }

    return label;
  }


  function checkIfThereIsAnySelectedPlan() {
    for (let i = 0; i < selectedPlans.length; i++) {
      if (plans.includes(selectedPlans[i])) {
        return true;
      }
    }

    return false;
  }

  function getExcessInPatientTreatmentsLabel() {
    const labels = [];

    for (let key in excess_in_patient_treatment) {
      labels.push(key);
    }

    return labels;
  }

  function computeMorganPriceRates(rates) {
    const computedRates = [];

    for (let i = 0; i < rates.length; i++) {
      computedRates.push({
        'client': rates[i].client,
        'currency': rates[i].currency,
        'core_ip': computedRate(rates[i].core_ip, rates[i].client.payment_frequency),
        'op_module_1': computedRate(rates[i].op_module_1, rates[i].client.payment_frequency),
        'op_module_2': computedRate(rates[i].op_module_2, rates[i].client.payment_frequency),
        'remove_op_co_insurance': computedRate(rates[i].remove_op_co_insurance, rates[i].client.payment_frequency),
        'reduce_overall_annual_limit': computedRate(rates[i].reduce_overall_annual_limit, rates[i].client.payment_frequency),
        'dental': computedRate(rates[i].dental, rates[i].client.payment_frequency),
        'maternity_module': computedRate(rates[i].maternity_module, rates[i].client.payment_frequency),
        'enhanced_network_module': computedRate(rates[i].enhanced_network_module, rates[i].client.payment_frequency),
      })
    }

    return computedRates;
  }

  function computeTotalPerClient(rates, index) {
    if (!response) return;

    if (!response.rates[index]) return;

    let total = 0;

    const core_ip = rates[index].core_ip;
    const op_module_1 = rates[index].op_module_1;
    const op_module_2 = rates[index].op_module_2;
    const remove_op_co_insurance = rates[index].remove_op_co_insurance;
    const reduce_overall_annual_limit = rates[index].reduce_overall_annual_limit;
    const dental = rates[index].dental;
    const maternity_module = rates[index].maternity_module;
    const enhanced_network_module = rates[index].enhanced_network_module;

    let arrayRatesObjects = [
      { name: 'core_ip', value: core_ip },
      { name: 'op_module_1', value: op_module_1 },
      { name: 'op_module_2', value: op_module_2 },
      { name: 'remove_op_co_insurance', value: remove_op_co_insurance },
      { name: 'reduce_overall_annual_limit', value: reduce_overall_annual_limit },
      { name: 'dental', value: dental },
      { name: 'maternity_module', value: maternity_module },
      { name: 'enhanced_network_module', value: enhanced_network_module }
    ];

    arrayRatesObjects.forEach(arrRate => {
      if (arrRate.name === 'core_ip') {
        if (!(arrRate.value === 'Unavailable' || typeof arrRate.value === 'string')) {
          total += arrRate.value;
        }
      } else if (optionalCovers[arrRate.name]) {
        if (!(arrRate.value === 'Unavailable' || typeof arrRate.value === 'string')) {
          total += arrRate.value;
        }
      }
    });

    const discount = discounts[id];
    const totalPercent = (100 - discount) * 0.01;

    return total * totalPercent;
  }

  function computeGrandTotal() {
    if (!response) return;

    // if (!response.rates[index]) return;

    let total = 0;

    const rates = response ? computeMorganPriceRates(response.rates) : [];

    for (let i = 0; i < clients.length; i++) {
      total += computeTotalPerClient(rates, i);
    }

    return total;
  }

  function computedRate(rate, payment_frequency) {
    if (rate === 'Unavailable' || typeof rate === 'string') {
      return rate;
    }

    const currency = specificCurrency[id];

    if (currency !== 'USD') {
      return 'Unavailable';
    }

    let total = rate;

    const excess_rate = excess_in_patient_treatment[excessInPatientTreatment];

    total *= excess_rate;

    // let _areaOfCoverage = area_of_coverage;

    // if (specificAreaOfCoverage[id] === 'parent') {
    //   _areaOfCoverage = area_of_coverage;
    // } else {
    //   _areaOfCoverage = specificAreaOfCoverage[id];
    // }


    // const deductiblePercent = specific_deductibles[deductibles[id]][plan];
    // const deductible = rate * deductiblePercent;

    // total = rate - deductible;

    // total += addOnValue;

    // if (coverageRestriction === 'Yes') {
    //   const coverageRestrictionPercent = coverage_restrictions[_areaOfCoverage];
    //   // console.log(area_of_coverage, coverageRestrictionPercent, coverage_restrictions);
    //   total = total - (total * coverageRestrictionPercent);
    // }

    const payment_frequency_rate = payment_frequency_rates[payment_frequency];
    const paymentFrequencyDivide = getPaymentFrequencyDivided(payment_frequency);

    total *= payment_frequency_rate;

    total /= paymentFrequencyDivide;

    return total;
  }

  function getPaymentFrequencyDivided(payment_frequency) {
    if (payment_frequency === 'Monthly') {
      return 12;
    } else if (payment_frequency === 'Quarterly') {
      return 4;
    } else if (payment_frequency === 'Semi Annually') {
      return 2;
    } else if (payment_frequency === 'Annually') {
      return 1;
    }

    return 1;
  }

  function onInputDiscountChange(e) {
    var numericValue = e.target.value.replace(/[^0-9]/g, '');

    // Allow single '0' but remove leading zeros for other values
    if (numericValue !== '0') {
      numericValue = numericValue.replace(/^0+/, '');
    }

    if (numericValue > 100) {
      numericValue = 100;
    }

    discounts[id] = numericValue;

    setDiscounts({ ...discounts });
  }

  function getAreaOfCoverageKey(area_of_coverage) {
    // let key = '';

    for (var key in area_of_coverages) {
      if (area_of_coverage === area_of_coverages[key]) {
        return key;
      }
    }

    return '';
  }

  function formatRate(rate) {
    if (isNaN(rate) || rate == 'Unavailable' || typeof rate === 'string') {
      return <span style={{ color: '#f44336' }}>{'Unavailable'}</span>
    }

    if (isNaN(rate)) {
      return rate;
    }

    return getCurrencySign() + rate.toLocaleString('en-PH', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  function getCurrencySign() {
    const currency = specificCurrency[id];

    if (currency === 'USD') {
      return '$';
    } else if (currency === 'EURO') {
      return '€';
    } else if (currency === 'GBP') {
      return '£';
    } else if (currency === 'SGD') {
      return 'SGD';
    }

    return '';
  }

  return (
    <Box sx={sx}>
      <Paper sx={{ borderColor: '#e0e0e0', borderWidth: 2, pb: 2 }} variant='outlined'>

        <Box sx={{ color: '#fff', background: '#0C76BD', px: 2, py: 1 }}>
          <Typography variant='h5'>Morgan Price International</Typography>
          <Typography sx={{ fontWeight: 500 }}>Flexible Choices Asia</Typography>
        </Box>
        <Box sx={{ p: 2 }}>

          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography fontSize={18} fontWeight={600}>Plan Type</Typography>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='morgan_price_core_ip' />} label="Core IP" />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography fontSize={18} fontWeight={600}>Optional Cover</Typography>
              <FormControlLabel control={<Checkbox checked={optionalCovers.op_module_1}
                onChange={(e) => { setOptionalCovers({ ...optionalCovers, op_module_1: e.target.checked }) }} />}
                label="OP Module 1" />
              <FormControlLabel control={<Checkbox checked={optionalCovers.op_module_2}
                onChange={(e) => { setOptionalCovers({ ...optionalCovers, op_module_2: e.target.checked }) }} />}
                label="OP Module 2" />
              <FormControlLabel control={<Checkbox checked={optionalCovers.remove_op_co_insurance}
                onChange={(e) => { setOptionalCovers({ ...optionalCovers, remove_op_co_insurance: e.target.checked }) }} />}
                label="Remove OP Co-Insurance" />
              <FormControlLabel control={<Checkbox checked={optionalCovers.reduce_overall_annual_limit}
                onChange={(e) => { setOptionalCovers({ ...optionalCovers, reduce_overall_annual_limit: e.target.checked }) }} />}
                label="Reduce Overall Annual Limit to $250,000" />
              <FormControlLabel control={<Checkbox checked={optionalCovers.dental}
                onChange={(e) => { setOptionalCovers({ ...optionalCovers, dental: e.target.checked }) }} />}
                label="Dental" />
              <FormControlLabel control={<Checkbox checked={optionalCovers.maternity_module}
                onChange={(e) => { setOptionalCovers({ ...optionalCovers, maternity_module: e.target.checked }) }} />}
                label="Maternity Module" />
              <FormControlLabel control={<Checkbox checked={optionalCovers.enhanced_network_module}
                onChange={(e) => { setOptionalCovers({ ...optionalCovers, enhanced_network_module: e.target.checked }) }} />}
                label="Enhanced Network Module" />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Area</Typography>
                <Select
                  size='small'
                  value={specificAreaOfCoverage[id]}
                  onChange={(e) => {
                    specificAreaOfCoverage[id] = e.target.value;
                    setSpecificAreaOfCoverage({ ...specificAreaOfCoverage });

                    addToPendingTasks([id]);
                  }}
                  sx={{ fontSize: 12, height: 40, width: '190px', minWidth: 190 }}
                  fullWidth
                  disabled={loadingStatus[id]}
                >
                  {/* <MenuItem value={'parent'} sx={{ color: colors.blue[500] }}>{areaOfCoverage}</MenuItem> */}
                  {
                    specific_area_of_coverages && specific_area_of_coverages.map(area_of_coverage => (
                      <MenuItem key={area_of_coverage} value={area_of_coverage}>{getAreaOfCoverageKey(area_of_coverage)}</MenuItem>
                    ))
                  }
                </Select>

              </Box>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Area of Coverage</Typography>
                <Typography sx={{ maxWidth: 200, fontSize: 14 }}>{specificAreaOfCoverage[id]}</Typography>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Currency</Typography>
                <Select
                  size='small'
                  value={specificCurrency[id]}
                  onChange={(e) => {
                    specificCurrency[id] = e.target.value;
                    setSpecificCurrency({ ...specificCurrency });

                    // clearResponse();
                    
                    setDeductibles(prev => {
                      return { 
                        ...prev, 
                        [id]: 'Nil Deductible'
                      };
                    });
                    

                    addToPendingTasks([id]);
                  }}
                  sx={{ fontSize: 12, height: 40, width: '190px', minWidth: 190 }}
                  fullWidth
                  disabled={loadingStatus[id]}
                >
                  {
                    currencies && currencies.map(currency => (
                      <MenuItem key={currency} value={currency}>{currency}</MenuItem>
                    ))
                  }

                </Select>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Excess applicable to In-Patient treatment</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <CustomSelect2
                    sx={{ minWidth: 190 }}
                    value={excessInPatientTreatment}
                    onChange={(e) => {
                      setExcessInPatientTreatment(e.target.value);
                    }}
                    items={getExcessInPatientTreatmentsLabel()}
                    disabled={loadingStatus[id]}
                    isZeroDeductibleUniform={false}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Renewal/Further Discount</Typography>
                <TextField
                  size='small'
                  sx={{ width: '80px', textAlign: 'end' }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  value={discounts[id]}
                  onChange={onInputDiscountChange}
                  autoComplete="off"
                />
              </Box>
            </Box>
          </Box>



          <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
            <Button
              size='small'
              sx={{ mb: 1 }}
              onClick={() => {
                var node = document.getElementById('table-morgan-price-rates');

                node.style = 'width: auto;';

                domtoimage.toBlob(node).then(function (blob) {
                  // Create ClipboardItem with blob and its type, and add to an array
                  const data = [new ClipboardItem({ [blob.type]: blob })];
                  // Write the data to the clipboard
                  navigator.clipboard.write(data);

                  setShowImageCopy(true);

                  node.style = 'width: 100%;';
                })
                  .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                    node.style = 'width: 100%;';
                  });
              }}
              endIcon={<ContentCopyIcon />}
            >Copy Image</Button>
          </div>

          <TableContainer component={Paper}>
            <Table id='table-morgan-price-rates' size='small' sx={{ minWidth: 650 }}>
              <TableHead sx={{ background: '#0C76BD', color: '#fff' }}>
                <TableRow>
                  <TableCell sx={{ width: 260, color: '#fff', fontWeight: 600 }}>Plan</TableCell>
                  {
                    clients.map((client, idx) => {
                      if (client.full_name.trim() === '') {
                        return <TableCell sx={{ width: 200, fontWeight: 600, color: '#fff' }} key={idx}>_</TableCell>
                      }

                      return <TableCell sx={{ width: 200, color: '#fff', fontWeight: 600 }} key={idx}><div>{client.full_name} ({client.age})</div><div>{client.country_of_residence}</div></TableCell>
                    })
                  }
                  <TableCell sx={{ color: '#fff', fontWeight: 600 }}>Subtotal</TableCell>
                </TableRow>

              </TableHead>
              <TableBody>
                <TableRatesRow
                  planId='morgan_price_core_ip'
                  planName='core_ip'
                  planLabel='Core IP'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#b39ddb'
                  loading={loadingStatus[id]}
                  rates={response ? computeMorganPriceRates(response.rates) : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('morgan_price_core_ip')}
                />
                <TableRatesRow
                  planId='morgan_price_op_module_1'
                  planName='op_module_1'
                  planLabel='OP Module 1'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#b39ddb'
                  loading={loadingStatus[id]}
                  rates={response ? computeMorganPriceRates(response.rates) : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={optionalCovers.op_module_1}
                />
                <TableRatesRow
                  planId='morgan_price_op_module_2'
                  planName='op_module_2'
                  planLabel='OP Module 2'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#b39ddb'
                  loading={loadingStatus[id]}
                  rates={response ? computeMorganPriceRates(response.rates) : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={optionalCovers.op_module_2}
                />
                <TableRatesRow
                  planId='morgan_price_remove_op_co_insurance'
                  planName='remove_op_co_insurance'
                  planLabel='Remove OP Co-Insurance'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#b39ddb'
                  loading={loadingStatus[id]}
                  rates={response ? computeMorganPriceRates(response.rates) : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={optionalCovers.remove_op_co_insurance}
                />
                <TableRatesRow
                  planId='morgan_price_reduce_overall_annual_limit'
                  planName='reduce_overall_annual_limit'
                  planLabel='Reduce Overall Annual Limit to $250,000'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#b39ddb'
                  loading={loadingStatus[id]}
                  rates={response ? computeMorganPriceRates(response.rates) : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={optionalCovers.reduce_overall_annual_limit}
                />
                <TableRatesRow
                  planId='morgan_price_dental'
                  planName='dental'
                  planLabel='Dental'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#b39ddb'
                  loading={loadingStatus[id]}
                  rates={response ? computeMorganPriceRates(response.rates) : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={optionalCovers.dental}
                />
                <TableRatesRow
                  planId='morgan_price_maternity_module'
                  planName='maternity_module'
                  planLabel='Maternity Module'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#b39ddb'
                  loading={loadingStatus[id]}
                  rates={response ? computeMorganPriceRates(response.rates) : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={optionalCovers.maternity_module}
                />
                <TableRatesRow
                  planId='morgan_price_enhanced_network_module'
                  planName='enhanced_network_module'
                  planLabel='Enhanced Network Module'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#b39ddb'
                  loading={loadingStatus[id]}
                  rates={response ? computeMorganPriceRates(response.rates) : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={optionalCovers.enhanced_network_module}
                />
                <TableRow sx={{ backgroundColor: '#fff' }}>
                  <TableCell sx={{ backgroundColor: '#fff' }} colSpan={clients.length + 2}>&nbsp;</TableCell>
                </TableRow>
                <TableRow ref={tableRowRef}>
                  <TableCell sx={{ backgroundColor: colors.grey[100], height: maxHeight, border: '2px solid #fff', fontWeight: 600 }}>Total</TableCell>
                  {
                    clients.map((client, index) => {
                      return <TableCell key={client.uid} sx={{ backgroundColor: colors.grey[100], height: maxHeight, border: '2px solid #fff' }}>{loadingStatus[id] ? '' : formatRate(computeTotalPerClient(response ? computeMorganPriceRates(response.rates) : [], index))}</TableCell>
                    })
                  }
                  <TableCell sx={{ backgroundColor: colors.grey[100], height: maxHeight, border: '2px solid #fff' }}>{loadingStatus[id] ? '' : formatRate(computeGrandTotal())}</TableCell>
                </TableRow>

              </TableBody>
            </Table>

          </TableContainer>


        </Box>


      </Paper>

    </Box>
  );
});

export default MorganPrice_Rates;